<div *ngIf="tag" class="profiling" fxLayout="column" fxLayoutAlign="start center" [ngClass]="_libraryStyle"
  (click)="onClick()">

  <div class="profiling-content" [ngClass]="{'selected': tag?.selected}" fxLayout="column"
    fxLayoutAlign="center center">

    <svg-icon *ngIf="tooltip" src="assets/icons/icon-help-circle.svg" class="help-circle"
      (click)="onClickTooltip($event)" [ngClass]="{'selected': _selectedTooltip}">
    </svg-icon>

    <svg-icon *ngIf="showInfoModal" src="assets/icons/icon-help-circle.svg" class="help-circle"
      (click)="emitOnInfoModalOpen($event)">
    </svg-icon>

    <svg-icon *ngIf="tag.selected" [src]="(tag.srcClicked || tag.src) || ''" class="profiling-icon" [applyClass]="true">
    </svg-icon>
    <svg-icon *ngIf="!tag.selected" [src]="tag.src || ''" class="profiling-icon" [applyClass]="true"></svg-icon>
  </div>

  <h5 [ngClass]="{'max-width96': tag.id == 'TALENT_FARM_COMMUNICATION_SUSTAINABILITY'}"
    [fxFlexOffset]="isSmartEnergy ? '6px' : '12px'" class="profiling-title" tabindex="-1">{{ tag.title || '' }}</h5>

</div>