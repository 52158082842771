import { Component, ContentChild, Inject, Input, OnInit, TemplateRef } from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';

import { BlogPost } from 'atfcore-commonclasses/bin/classes/blog';

import { LibraryStyle, LIBRARY_STYLE } from '../utils';

@Component({
  selector: 'generali-hero-blog',
  templateUrl: './hero-blog.component.html',
  styleUrls: ['./hero-blog.component.scss']
})
export class GeneraliHeroBlogComponent implements OnInit {

  _libraryStyle: LibraryStyle;

  @Input() sectionId: string;

  _isLoading: boolean = false;
  @Input() set isLoading(value: boolean | string) {
    this._isLoading = coerceBooleanProperty(value);
  };

  _buttonDisabled: boolean = false;
  @Input() set buttonDisabled(value: boolean | string) {
    this._buttonDisabled = coerceBooleanProperty(value);
  };

  _articles: BlogPost[] = [];
  @Input() set articles(value: BlogPost[]) {
    if (value) {
      if (value.length > 5) {
        value = value.slice(0, 5);
      }
      this._articles = value;
    }
  };

  /**
   * Time expressed in seconds for autoscroll
   */
  _autoScrollTime: number = 10;
  @Input() set autoScrollTime(value: number) {
    this._autoScrollTime = value ? value : 10;
  };

  _scrollToLeft: Subject<void> = new Subject<void>();
  _scrollToRight: Subject<void> = new Subject<void>();

  @ContentChild('showAllButton', { static: false }) showAllButton: TemplateRef<any>;

  get isSmartEnergy(): boolean { return this._libraryStyle === 'SMART_ENERGY' };

  private _disabledArrows = false;

  constructor(@Inject(LIBRARY_STYLE) libraryStyle: LibraryStyle) {
    this._libraryStyle = libraryStyle;
  }

  ngOnInit() {
    if (!this.sectionId) {
      this.sectionId = 'hero-blog-' + Math.floor((Math.random() * 10000) + 1);
    }
  }

  scrollLeft() {
    if (!this._disabledArrows || this.isLoading) {
      this._disabledArrows = true;
      this._scrollToLeft.next();
    }
  }

  scrollRight() {
    if (!this._disabledArrows || this.isLoading) {
      this._disabledArrows = true;
      this._scrollToRight.next();
    }
  }

  trackByItemId(index: any, item: any) {
    return item.itemId;
  }

  onScrollEvent(index: number) {
    this._disabledArrows = false;
  }

}
