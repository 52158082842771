<generali-carousel [manageSwipe]="true" [fromHero]="true" [isFullWindow]="true" [autoScrollTime]="_autoScrollTime"
  autoScroll noPlaceholder [sectionId]="sectionId" [preventOverflowMarginOnRight]="true"
  [contentLength]="_titleTabs.length" [titleTabs]="_titleTabs" [isLoading]="_isLoading" [index]="0">
  <!-- Loader -->
  <ng-container *ngIf="_isLoading">
    <div class="hero">
      <generali-loader></generali-loader>
    </div>
  </ng-container>
  <!-- Contenuto carosello statico -->
  <ng-container *ngIf="!_isLoading && !hasCustomTabs">
    <div class="hero" *ngFor="let tab of _heroTabs; trackBy: trackBy">

      <div class="hero-content no-after" *ngIf="tab.isEnergyLabLink"
      [ngClass]="_libraryStyle + ' ' + (tab.noCover ? 'noCover' : 'cover')"
      fxLayout="column" fxLayoutAlign="start start"
      [ngStyle]="{'background-image': tab.coverImage ? ('url(' + tab.coverImage + ')') : '' }">

      <div class="hero-text" fxFlexOffset="178px"
          fxFlexOffset.xs="140px">
          <h1 tabindex="-1">
            {{ 'hero.DISCOVER_ENERGY_LAB' | translate }}
          </h1>
        </div>

        <ng-container>
          <h5 class="bold-text" fxFlexOffset="24px" fxFlexOffset.xs="30px" tabindex="-1"
            translate="hero.SUBSCRIBE_TO_NEW_COURSES"></h5>
        </ng-container>

        <ng-container [ngTemplateOutlet]="onEnergyLabButton">
        </ng-container>
      </div>

      <div class="hero-content" *ngIf="!tab.isEnergyLabLink"
        [ngClass]="_libraryStyle + ' ' + (tab.noCover ? 'noCover' : 'cover') + ' ' + (tab.type === 'job' ? 'padding-left-job' : '' ) + ' ' + (tab.type === 'course' ? 'padding-left-course' : '' ) + ' ' + (tab.type === 'general-event' ? 'padding-left-event' : '' ) + ' ' + (tab.type === 'challenge' ? 'padding-left-challenge' : '' )"
        fxLayout="column" fxLayoutAlign="start start"
        [ngStyle]="{'background-image': tab.coverImage ? ('url(' + tab.coverImage + ')') : '' }">

        <div *ngIf="tab.title" class="hero-text" [fxFlexOffset]="isSmartEnergy ? '178px' : '136px'"
          [fxFlexOffset.xs]="isSmartEnergy ? '140px' : '130px'">
          <h1 class="flex-show-gt-md" tabindex="-1"
            [ngClass]="{'only-2-rows': isSmartEnergy, 'max-width530': !isSmartEnergy}">
            {{ ((tab.title | translate).length>94)? ((tab.title | translate) | slice:0:94)+'...':((tab.title |
            translate)) }}
          </h1>
          <h1 fxHide fxShow.md fxShow.sm tabindex="-1" [ngClass]="{'only-2-rows-md-sm': isSmartEnergy}">
            {{ ((tab.title | translate).length>48)? ((tab.title | translate) | slice:0:48)+'...':((tab.title |
            translate)) }}
          </h1>
          <h1 class="flex-show-xs" tabindex="-1">
            {{ ((tab.title | translate).length>32)? ((tab.title | translate) | slice:0:32)+'...':((tab.title |
            translate)) }}
          </h1>
        </div>

        <!-- Contenuto live -->
        <ng-container
          *ngIf="tab.type === 'live-event' || tab.type === 'live-event-top' || tab.type === 'top-ten-courses'">
          <h5 fxFlexOffset="24px" fxFlexOffset.xs="30px" tabindex="-1">
            <!-- <ng-container *ngIf="!tab.isToday">
              {{ "hero.LIVE_ON" | translate: { value: (tab.startDate | date: 'dd.MM.yyyy')} }}
            </ng-container>
            <ng-container *ngIf="tab.isToday">
              {{ "hero.LIVE_TODAY" | translate }}
            </ng-container> -->
            {{ "hero.LIVE_SMART_ENERGY_EXPERIENCE" | translate }}
          </h5>
        </ng-container>

        <!-- Contenuto on demand -->
        <ng-container *ngIf="tab.type === 'on-demand-event' && isSmartEnergy">
          <h5 class="bold-text" fxFlexOffset="24px" fxFlexOffset.xs="30px" tabindex="-1"
            translate="hero.ON_DEMAND_CONTENT"></h5>
        </ng-container>

        <!-- Contenuto blog / news -->
        <ng-container *ngIf="tab.type === 'blog' && isSmartEnergy">
          <h5 class="bold-text" fxFlexOffset="24px" fxFlexOffset.xs="30px" tabindex="-1" translate="hero.BLOG_CONTENT">
          </h5>
        </ng-container>

        <ng-container *ngIf="!isSmartEnergy">
          <h5 *ngIf="tab.type != 'course'" fxFlexOffset="24px" fxFlexOffset.xs="30px" tabindex="-1">{{ tab.description |
            translate }}</h5>
          <h5 *ngIf="tab.type == 'course'" fxFlexOffset="24px" fxFlexOffset.xs="30px" tabindex="-1"
            [innerHTML]="tab.description | translate"></h5>
        </ng-container>

        <div class="hero-button-container" [fxFlexOffset]="isSmartEnergy ? '46px' : '32px'"
          [fxFlexOffset.xs]="isSmartEnergy ? '32px' : '26px'" fxLayout="row">

          <!-- Eventi live -->
          <ng-container *ngIf="tab.type === 'live-event' && liveButtons" [ngTemplateOutlet]="liveButtons">
          </ng-container>

          <!-- Eventi On Demand -->
          <ng-container *ngIf="tab.type === 'on-demand-event' && onDemandButtons" [ngTemplateOutlet]="onDemandButtons"
            [ngTemplateOutletContext]="{ $implicit: tab, index: trackBy}">
          </ng-container>

          <!-- Blog -->
          <ng-container *ngIf="tab.type === 'blog' && blogButtons" [ngTemplateOutlet]="blogButtons">
          </ng-container>

          <!-- Eventi -->
          <ng-container *ngIf="tab.type === 'general-event' && eventButtons" [ngTemplateOutlet]="eventButtons">
          </ng-container>

          <!-- Corsi -->
          <ng-container *ngIf="tab.type === 'course' && courseButtons" [ngTemplateOutlet]="courseButtons">
          </ng-container>

          <!-- Challenge -->
          <ng-container *ngIf="tab.type === 'challenge' && challengeButtons" [ngTemplateOutlet]="challengeButtons">
          </ng-container>

          <!-- Posizioni 
          <ng-container *ngIf="tab.type === 'job' && jobButtons" [ngTemplateOutlet]="jobButtons">
          </ng-container>-->

          <!-- Premi -->
          <ng-container *ngIf="tab.type === 'premium' && prizeButtons" [ngTemplateOutlet]="prizeButtons">
          </ng-container>

        </div>

        <div *ngIf="tab.noCover" class="hero-image-no-cover" fxLayout="column" fxLayoutAlign="center center"
          fxLayoutAlign.xs="start center">

          <!-- Blog -->
          <ng-container *ngIf="tab.type === 'blog'">
            <!--<svg-icon class="flex-hide-lt-md" src="assets/img/hero/insight.svg"></svg-icon>
            <svg-icon class="flex-show-lt-md" src="assets/img/hero/insight-mob.svg"></svg-icon>-->
            <img class="flex-hide-lt-md" alt="Blog" src="assets/img/hero/insight.png" />
            <img class="flex-show-lt-md" alt="Blog" src="assets/img/hero/insight-mob.png" />
          </ng-container>

          <!-- Eventi -->
          <ng-container *ngIf="tab.type === 'general-event'">
            <!-- <svg-icon class="flex-hide-lt-md" src="assets/img/hero/events.svg"></svg-icon>
            <svg-icon class="flex-show-lt-md" src="assets/img/hero/events-mob.svg"></svg-icon>-->
            <img class="flex-hide-lt-md" alt="Eventi" src="assets/img/hero/events.png" />
            <img class="flex-show-lt-md" alt="Eventi" src="assets/img/hero/events-mob.png" />
          </ng-container>

          <!-- Corsi -->
          <ng-container *ngIf="tab.type === 'course'">
            <!-- <svg-icon class="flex-hide-lt-md" src="assets/img/hero/online-courses.svg"></svg-icon>
            <svg-icon class="flex-show-lt-md" src="assets/img/hero/online-courses-mob.svg"></svg-icon>-->
            <img class="flex-hide-lt-md" alt="Corsi" src="assets/img/hero/online-courses.png" />
            <img class="flex-show-lt-md" alt="Corsi" src="assets/img/hero/online-courses-mob.png" />
          </ng-container>

          <!-- Challenge -->
          <ng-container *ngIf="tab.type === 'challenge'">
            <!--<svg-icon class="flex-hide-lt-md" src="assets/img/hero/challenges.svg"></svg-icon>
            <svg-icon class="flex-show-lt-md" src="assets/img/hero/challenges-mob.svg"></svg-icon> -->
            <img class="flex-hide-lt-md" alt="Challenge" src="assets/img/hero/challenges.png" />
            <img class="flex-show-lt-md" alt="Challenge" src="assets/img/hero/challenges-mob.png" />
          </ng-container>

          <!-- Posizioni
          <ng-container *ngIf="tab.type === 'job'">
            <!--<svg-icon class="flex-hide-lt-md" src="assets/img/hero/opened-jobs.svg"></svg-icon>
            <svg-icon class="flex-show-lt-md" src="assets/img/hero/opened-jobs-mob.svg"></svg-icon> 
            <img class="flex-hide-lt-md" alt="Posizioni" src="assets/img/hero/opened-jobs.png" />
            <img class="flex-show-lt-md" alt="Posizioni" src="assets/img/hero/opened-jobs-mob.png" />
          </ng-container>  -->

          <!-- Premi -->
          <ng-container *ngIf="tab.type === 'premium'">
            <img class="flex-hide-lt-md" alt="Premi" src="assets/img/hero/opened-jobs.png" />
            <img class="flex-show-lt-md" alt="Premi" src="assets/img/hero/opened-jobs-mob.png" />
          </ng-container>

        </div>

      </div>
    </div>
  </ng-container>

  <!-- Contenuto carosello dinamico (quindi con tab recuperate, ad es., da servizi api) -->
  <ng-container *ngIf="!_isLoading && hasCustomTabs && _heroTabs && _heroTabs.length">
    <div class="hero" *ngFor="let tab of _heroTabs; trackBy: trackBySlideId">

      <div class="hero-content" [ngClass]="_libraryStyle + ' ' + (tab.noCover ? 'noCover' : 'cover')" fxLayout="column"
        fxLayoutAlign="start start"
        [style.background-image]="'url(\'' + (tab.slideImage || '') + '\')'">

        <!-- Titolo -->
        <div *ngIf="tab.title" class="hero-text" fxFlexOffset="136px" fxFlexOffset.xs="130px">
          <h1 class="flex-show-gt-md max-width530" tabindex="-1">
            {{ ((tab.title | translate).length>94)? ((tab.title | translate) | slice:0:94)+'...':((tab.title |
            translate)) }}
          </h1>
          <h1 fxHide fxShow.md fxShow.sm tabindex="-1">
            {{ ((tab.title | translate).length>48)? ((tab.title | translate) | slice:0:48)+'...':((tab.title |
            translate)) }}
          </h1>
          <h1 class="flex-show-xs" tabindex="-1">
            {{ ((tab.title | translate).length>32)? ((tab.title | translate) | slice:0:32)+'...':((tab.title |
            translate)) }}
          </h1>
        </div>

        <!-- Sottotitolo -->
        <h5 fxFlexOffset="24px" fxFlexOffset.xs="30px" tabindex="-1" [innerHTML]="tab.subtitle || ''"></h5>

        <!-- Azione principale -->
        <div class="hero-button-container" fxFlexOffset="32px" fxFlexOffset.xs="26px" fxLayout="row">
          <ng-container [ngTemplateOutlet]="customButtons" [ngTemplateOutletContext]="{
            tab: tab
          }">
          </ng-container>
        </div>

        <!-- Immagine di default se non c'è quella di sfondo -->
        <div *ngIf="!tab.slideImage" class="hero-image-no-cover" fxLayout="column" fxLayoutAlign="center center"
          fxLayoutAlign.xs="start center">
          <!-- <svg-icon class="flex-hide-lt-md" src="assets/img/hero/online-courses.svg"></svg-icon>
            <svg-icon class="flex-show-lt-md" src="assets/img/hero/online-courses-mob.svg"></svg-icon>-->
          <img class="flex-hide-lt-md" alt="Corsi" src="assets/img/hero/online-courses.png" />
          <img class="flex-show-lt-md" alt="Corsi" src="assets/img/hero/online-courses-mob.png" />
        </div>

      </div>
    </div>
  </ng-container>

</generali-carousel>
