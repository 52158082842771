import { InjectionToken } from "@angular/core";

import { BlogPost } from "atfcore-commonclasses/bin/classes/blog";
import { CarouselCourseItem } from "atfcore-commonclasses/bin/classes/talent-farm";
import { OnDemandItem } from "atfcore-commonclasses/bin/classes/onDemandItem";
import { WebinarSession, JobInformation } from "atfcore-commonclasses/bin/classes/coursemanager";
import { TagInfo } from "atfcore-commonclasses/bin/classes/tag";

import { CategoryCardInterface, ChallengeCardInterface } from "./interfaces";

export function instanceOfWebinarSession(value): value is WebinarSession {
  return 'editionId' in value;
}

export function instanceOfBlogPost(value): value is BlogPost {
  return 'itemId' in value;
}

export function instanceOfOnDemandItem(value): value is OnDemandItem {
  return 'itemType' in value;
}

export function instanceOfJobInformation(value): value is JobInformation {
  return 'contestId' in value;
}

export function instanceOfCategory(value): value is CategoryCardInterface {
  return 'title' in value && 'itemImage' in value;
}

export function instanceOfCarouselCourseItem(value): value is CarouselCourseItem {
  return 'itemId' in value;
}

export function instanceOfChallenge(value): value is ChallengeCardInterface {
  return 'itemId' in value && 'durationInMinutes' in value;
}

/**
 * @param {TagInfo[]} args Arguments
 * @param {number} maxNum Max number of displayed arguments
 * @returns If the arguments are more then 3 cut the others
 */
export function checkArguments(args: TagInfo[], maxNum: number): TagInfo[] {
  if (args && args.length > maxNum) {
    args = args.slice(0, maxNum);
    args.push({ tagId: '', title: '…' });
    return args;
  } else return args;
}

export const LIBRARY_STYLE = new InjectionToken<LibraryStyle>('LIBRARY_STYLE');

export type LibraryStyle = 'SMART_ENERGY' | 'GO_GENERALI';

export interface GeneraliModuleConfig {
  libraryStyle?: LibraryStyle;
}

/**
 * Check day, month and year
 * @param firstDay
 * @param secondDay
 * @returns Return true if is the same date
 */
export function isSameDate(firstDay: Date, secondDay: Date) {
  return firstDay && secondDay &&
    firstDay.getDate() === secondDay.getDate() &&
    firstDay.getMonth() === secondDay.getMonth() &&
    firstDay.getFullYear() === secondDay.getFullYear();
}
