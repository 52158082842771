import {
  Component,
  Input,
  ChangeDetectionStrategy,
  OnInit,
  Output,
  EventEmitter,
  Inject,
  ViewChild,
  ChangeDetectorRef,
  OnDestroy
} from '@angular/core';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';

import { TagInfo } from 'atfcore-commonclasses/bin/classes/tag';

import { CardType } from '../card/card.component';
import { LibraryStyle, LIBRARY_STYLE } from '../utils';
import { GeneraliCarouselComponent } from '../carousel/carousel.component';

@Component({
  selector: 'generali-card-carousel',
  templateUrl: './card-carousel.component.html',
  styleUrls: ['./card-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GeneraliCardCarouselComponent implements OnInit, OnDestroy {
  @Input() type: CardType = 'course';
  @Input() showAllCards: boolean;
  @Input() isCarouselOfPrems: boolean;
  @Input() differentTypesForCard: boolean;
  @Input() withTotalItemsMoreOne: boolean;
  @Input() preventOverflowMarginOnRight: boolean;
  @Input() scrollWithoutScrollbar: boolean;
  @Input() preventSliceCards: boolean = false;
  @Input() showTooltip: boolean;
  /** Se true, l'aspetto della card cambia se l'evento è concluso */
  @Input() distinctForPastEvents?: boolean;
  /** Se true, il titolo della playlist verrà tagliato se oltre una riga */
  @Input() titleOnlyOneRow?: boolean;

  @Output() viewAllTap: EventEmitter<any> = new EventEmitter();
  @Output() onToggleBookmark: EventEmitter<any> = new EventEmitter();

  _cardList: any[];
  timeout;
  @Input() set cardList(value: any[]) {
    this._cardList = value;
    if (this._cardList) {
      this._cardLength = this._cardList.length;
      if (this._cardLength > 5 && !this.showAllCards && !this.preventSliceCards) {
        this._cardList = this._cardList.slice(0, 5);
      } else if (this._cardLength < 2 && !this._showNoLimits) {
      }
    }
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.checkShowArrow();
      clearTimeout(this.timeout);
    }, 100);
  }

  // Title of the card group title
  @Input() title: string;

  @Input() isLoadingItems: boolean;

  _translatedCourseType: string;
  @Input() set translatedCourseType(value: any) {
    this._translatedCourseType = value;
  }

  @Input() isVetrin: boolean;

  _hideAllButton: boolean = false;
  @Input() set hideAllButton(value: string | boolean) {
    this._hideAllButton = coerceBooleanProperty(value);
  }

  _showNoLimits: boolean = false;
  @Input() set showNoLimits(value: string | boolean) {
    this._showNoLimits = coerceBooleanProperty(value);
  }

  @Output() onCardClick: EventEmitter<any> = new EventEmitter();
  @Output() onTagClick: EventEmitter<TagInfo> = new EventEmitter();

  @Input() sectionId: string;

  @Input() itemCount?: number;

  _scrollToLeft: Subject<void> = new Subject<void>();
  _scrollToRight: Subject<void> = new Subject<void>();

  // If one or more cards are sliced
  _cardLength: number = 0;

  _libraryStyle: LibraryStyle;

  private _disabledArrows = false;
  private _showArrows = false;
  _showLeftArrow = false;
  _showRightArrow = false;

  get isTopLiveEvent(): boolean { return this.type === 'live-event-top' };
  get isTopTenCourses(): boolean { return this.type === 'top-ten-courses' };

  @ViewChild('carousel') carousel: GeneraliCarouselComponent;

  constructor(@Inject(LIBRARY_STYLE) libraryStyle: LibraryStyle, private cdRef: ChangeDetectorRef) {
    this._libraryStyle = libraryStyle;
  }

  ngOnInit() {
    if (!this.sectionId) {
      this.sectionId = 'card-carousel-' + Math.floor((Math.random() * 10000) + 1);
    }
  }

  trackBy(index: any, item: any) {
    return index;
  }

  isSmartEnergy() {
    return this._libraryStyle && this._libraryStyle === 'SMART_ENERGY';
  };

  private checkShowArrow(): void {
    const element = document.getElementById(this.sectionId);
    if (element) {
      this._showArrows = element.scrollWidth > element.clientWidth;
      this.checkVisibilityArrows();
    }
  }

  scrollLeft() {
    if (!this._disabledArrows || this.isLoadingItems) {
      this._disabledArrows = true;
      this._scrollToLeft.next();
    }
  }

  scrollRight() {
    if (!this._disabledArrows || this.isLoadingItems) {
      this._disabledArrows = true;
      this._scrollToRight.next();
    }
  }

  onScrollEvent(index: number) {
    this._disabledArrows = false;
    this.checkVisibilityArrows();
  }

  /**
   * Set the visibility of the arrows
   */
  private checkVisibilityArrows(): void {
    if (this.carousel && this._showArrows) {
      this._showLeftArrow = this.carousel.index > 0;
      const cardListLength = this._cardList && this._cardList.length || 0;
      this._showRightArrow = this.carousel.index < (cardListLength - (this._cardLength > 5 ? 1 : 0));
    } else {
      this._showLeftArrow = false;
      this._showRightArrow = false;
    }
    this.cdRef.detectChanges();
  }

  // Gestisce il click sull'icona del cuore della collezione
  emitToggleBookmark(data) {
    this.onToggleBookmark.emit(data);
  }

  ngOnDestroy() {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
  }

}
