import { Component, Inject, Input, OnInit } from '@angular/core';
import { LibraryStyle, LIBRARY_STYLE } from '../utils';

export type BadgeType = 'default' | 'premium-badge' | 'small-default' | 'primary' | 'accent' | 'white' | 'primary-tag' | 'secondary-tag' | 'third-tag' | 'transparent' | 'glp-badge';

@Component({
  selector: 'generali-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss']
})
export class GeneraliBadgeComponent implements OnInit {
  @Input() type: BadgeType = 'default';
  @Input() title: string;
  @Input() bigMarginToTitle?: boolean = false;
  @Input() isVetrin?: boolean = false;
  @Input() textToCenter?: boolean = false;
  @Input() coinChallengeClass?: string = '';
  @Input() bgTransparent?: boolean = false;

  _libraryStyle: LibraryStyle;
  isVetrinClass: string;

  constructor(@Inject(LIBRARY_STYLE) libraryStyle: LibraryStyle) {
    this._libraryStyle = libraryStyle;
  }

  ngOnInit() {
    if(this.isVetrin) {
      this.isVetrinClass = 'pointer-none';
    } else {
      this.isVetrinClass = '';
    }
  }
}
