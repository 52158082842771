import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';

import { LibraryStyle, LIBRARY_STYLE } from '../utils';

export interface ProfilingTag {
  id: string,
  title: string,
  selected: boolean,
  src: string,
  srcClicked?: string
}

@Component({
  selector: 'generali-profiling',
  templateUrl: './profiling.component.html',
  styleUrls: ['./profiling.component.scss']
})
export class GeneraliProfilingComponent {

  @Input() tag: ProfilingTag;

  @Input() tooltip: string;
  @Input() showInfoModal: boolean = false;

  @Output() onInfoTooltipClicked: EventEmitter<any> = new EventEmitter();
  @Output() onInfoModalOpen: EventEmitter<any> = new EventEmitter();

  _libraryStyle: LibraryStyle;

  _selectedTooltip: boolean = false;

  get isSmartEnergy(): boolean { return this._libraryStyle === 'SMART_ENERGY' };

  constructor(@Inject(LIBRARY_STYLE) libraryStyle: LibraryStyle) {
    this._libraryStyle = libraryStyle;
  }

  onClick() {
    if (this.tag) {
      this.tag.selected = !this.tag.selected;
    }
  }

  onClickTooltip(event: Event) {
    event.stopPropagation();
    this._selectedTooltip = !this._selectedTooltip;
    this.onInfoTooltipClicked.emit(this.tooltip);
  }

  emitOnInfoModalOpen(event: Event) {
    event.stopPropagation();
    this.onInfoModalOpen.emit(this.tag);
  }
}
