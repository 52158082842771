export const it = {
  card: {
    YOU_ARE_NOT_SUBSCRIBED: "Non sei ancora iscritto",
    SUBSCRIBED_ONLINE_LIVE: "iscrizione on line effettuata",
    SUBSCRIBED_ONLINE_PRESENCE: "iscrizione in presenza effettuata",
    SUBSCRIBED_ONLINE_LIVE_PRESENCE: "iscrizione in presenza e online effettuata",
    SUBSCRIBED_ONLINE_LIVE_PRESENCE_MOBILE: "iscrizione in presenza e online",
    NOT_SUBSCRIBED_EVENT: "Non sei ancora iscritto",
    VIEW_ALL_GO_LIVE_EVENT: "Vai al calendario",
    ARTICLE: "Articolo",
    VIDEO: "Video",
    PODCAST: "Podcast",
    VIEW_ALL: "Vedi tutti ({{value}})",
    VIEW_ALL_GO: "Vedi tutti ({{value}})",
    VIEW_ALL_GO_JOB: "Vedi tutte ({{value}})",
    SUBSCRIBED: "iscrizione effettuata",
    PARTICIPATED: "partecipazione effettuata",
    NOT_SUBSCRIBED: "iscriviti",
    NOT_SUBSCRIBED_F: "iscriviti",
    TO_START: "Da iniziare",
    NOT_ENROLLED: "Non sei ancora iscritto",
    NOT_ENROLLED_F: "Non sei ancora iscritta",
    TO_COMPLETE: "Da completare",
    NOT_PARTICIPATED: "partecipa",
    NOT_PARTICIPATED_F: "partecipa",
    REGISTRATIONS_CLOSED: "iscrizioni chiuse",
    PARTICIPATIONS_CLOSED: "partecipazioni chiuse",
    STARTED_FROM: "Iniziato da 1 minuto",
    STARTED_FROMS: "Iniziato da {{value}} minuti",
    DURATION: "Durata 1 minuto",
    DURATIONS: "Durata {{value}} minuti",
    COMPLETED: "Completato",
    PRIZE_TO_REDEEM: "Premio da riscattare",
    PRIZE_REDEEMED: "Premio riscattato",
    NOW_NOT_AVAILABLE: "Momentaneamente non disponibile",
    NOW_DISPONIBLE: "1 ancora disponibile",
    NOW_DISPONIBLES: " ancora disponibili",
    OTHER_EDITIONS: " altre edizioni",
    OTHER_LOCATION: " altro luogo",
    OTHER_LOCATIONS: " altri luoghi",
    MULTIPLE_EDITIONS_EVENT: "Evento con più edizioni",
    EVENT_ENDED: "Evento concluso",
    APPLICATION_SENT: "Candidatura inviata"
  },
  hero: {
    CUSTOM_LINK_ENERGY_HUB: "Link Energy Hub",
    LIVE_SMART_ENERGY_EXPERIENCE: "Vivi l’esperienza Smart Energy",
    BLOG: "BLOG",
    LIVE: "LIVE",
    ON_DEMAND: "ON DEMAND",
    NEWS: "INSIGHT",
    PRIZES: "PREMI",
    EVENTS: "EVENTI",
    COURSES: "CORSI",
    CHALLENGES: "SFIDE",
    JOBS: "POSIZIONI",
    LIVE_ON: "Live il {{ value }}",
    LIVE_TODAY: "Live oggi",
    AT: "alle {{ value }}",
    ENROLL: "Iscriviti",
    CANCEL_ENROLL: "Disiscriviti",
    ON_DEMAND_CONTENT: "Contenuto on demand",
    BLOG_CONTENT: "Approfondimento",
    LEARN_MORE: "Approfondisci",
    LOOK: "Guarda ora",
    SAVE: "Salva",
    REMOVE_FROM_SAVE: "Rimuovi",
    news: {
      TITLE: "Insight",
      DESCRIPTION: "Scopri e leggi i temi di tuo interesse"
    },
    prizes: {
      TITLE: "Premi",
      DESCRIPTION: "Scopri e consulta i premi"
    },
    jobs: {
      TITLE: "Posizioni",
      DESCRIPTION: "Scopri le ultime posizioni aperte in Generali e manda la tua candidatura"
    },
    courses: {
      TITLE: "Corsi",
      DESCRIPTION: "Scopri i corsi di tuo interesse e impara<br> nuove skill"
    },
    events: {
      TITLE: "Eventi",
      DESCRIPTION: "Partecipa agli eventi di tuo interesse, online e dal vivo"
    },
    challenges: {
      TITLE: "Sfide",
      DESCRIPTION: "Completa le challenge per vincere coin e approfittare del contenuto Premium"
    },
    DISCOVER_ENERGY_LAB: "Scopri Energy Hub",
    SUBSCRIBE_TO_NEW_COURSES: "Iscriviti ai nuovi corsi",
    ENERGY_HUB: "Energy Hub",
    ENERGY_HUB_SCADENCE_COURSES_DESC: "I corsi terminano mercoledì 20.12. Riprenderanno mercoledì 10.01"
  }
};
