import { Component } from '@angular/core';

@Component({
  selector: 'generali-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class GeneraliLoaderComponent {

  constructor() {
  }

}
