<div *ngIf="isLoading || _mainArticle" class="generali-home-blog" [ngClass]="_libraryStyle" fxLayout="column">

  <!-- Header -->
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <h1 tabindex="-1">{{ title }}</h1>

    <h4 *ngIf="!isLoading && !_hideViewAll" class="flex-hide-xs" (click)="viewAllTap.emit()">
      <ng-container *ngIf="isSmartEnergy">
        {{ 'card.VIEW_ALL' | translate: { value: (articleCount ? articleCount : _articlesLength) } }}
      </ng-container>
      <ng-container *ngIf="!isSmartEnergy">
        {{ 'card.VIEW_ALL_GO' | translate: { value: (articleCount ? articleCount : _articlesLength) } | uppercase }}
      </ng-container>
    </h4>

    <svg-icon *ngIf="!isLoading && !_hideViewAll" class="flex-show-xs" (click)="viewAllTap.emit()"
      src="assets/icons/icon-view-all-{{isSmartEnergy ? 'smart' : 'go'}}.svg"></svg-icon>

  </div>

  <!-- Loader -->
  <ng-container *ngIf="isLoading && !_mainArticle">
    <generali-loader></generali-loader>
  </ng-container>

  <!-- Corpo -->
  <div *ngIf="!isLoading && _mainArticle" [fxFlexOffset]="isSmartEnergy ? '48px' : '42px'"
    [fxFlexOffset.xs]="isSmartEnergy ? '30px' : '16px'" fxLayout="row" fxLayout.lt-md="column">

    <!-- Main article -->
    <div class="main-article" fxLayout="column" (click)="onArticleClick(_mainArticle)">

      <!-- Immagine -->
      <div class="main-article-image"
        [ngStyle]="{ 'background-image': 'url(' + (_mainArticle?.postImage || defaultImage || 'assets/img/pl.png') + ')' }">
      </div>

      <p fxFlexOffset="10px" fxFlexOffset.xs="6px" *ngIf="!isSmartEnergy && _mainArticle.contentType"
        class="red-type-text">{{('card.' + _mainArticle.contentType) | translate}}</p>

      <!-- Titolo -->
      <h2 [ngClass]="_mainArticle.contentType ? 'margin-top2' : 'margin-top16'"
        [ngClass.xs]="_mainArticle.contentType ? 'margin-top2' : 'margin-top8'" *ngIf="_mainArticle.title">{{
        (_mainArticle.title.length>65)?
        (_mainArticle.title |
        slice:0:65)+'...':(_mainArticle.title) }}
      </h2>
      <!-- Data -->
      <h6 fxFlexOffset="10px" *ngIf="_mainArticle.creationDate" fxFlexOffset.xs="8px">{{ _mainArticle.creationDate |
        date: 'dd MMMM yyyy' }}</h6>
      <!-- Descrizione -->
      <h3 class="only-2-rows" [innerHTML]="_mainArticle.subTitle" fxFlexOffset="20px" *ngIf="_mainArticle.subTitle"
        fxFlexOffset.xs="10px">
      </h3>

      <!-- Tags -->
      <div fxFlexOffset="6px" fxFlexOffset.xs="4px" fxLayout="row wrap" fxLayoutAlign="start center">

        <generali-badge *ngFor="let argument of _mainArticle.arguments; let first = first; trackBy: trackByTagId"
          type="third-tag" (click)="onCategoryClick($event, argument)" [fxFlexOffset]="first ? '0px' : '15px'"
          [title]="argument.title">
        </generali-badge>

      </div>


    </div>

    <!-- Other articles -->
    <div fxFlex fxLayout="column" fxFlexOffset="30px">

      <div *ngFor="let article of _otherArticles; let first = first; trackBy: trackByItemId" class="other-article"
        fxLayout="row" [fxFlexOffset]="first ? '0px' : '30px'" [fxFlexOffset.xs]="first ? '0px' : '28px'"
        (click)="onArticleClick(article)">

        <!-- Immagine -->
        <div class="other-article-image"
          [ngStyle]="{ 'background-image': 'url(' + (article.postImage || defaultImage || 'assets/img/pl.png') + ')' }">
        </div>

        <div fxFlex fxLayout="column" fxFlexOffset="15px" fxFlexOffset.xs="6px">

          <p *ngIf="!isSmartEnergy && article.contentType" class="red-type-text">{{('card.' + article.contentType) |
            translate}}</p>

          <!-- Titolo -->
          <h3 [ngClass]="{'margin-top4': article.contentType}" [ngClass.xs]="{'margin-top6': article.contentType}">{{
            (article.title.length>65)? (article.title |
            slice:0:65)+'...':(article.title) }}
          </h3>
          <!-- Data -->
          <h6 fxFlexOffset="4px" fxFlexOffset.xs="6px">{{ article.creationDate | date: 'dd MMMM yyyy' }}</h6>

          <!-- Tags -->
          <div fxFlexOffset="10px" fxFlexOffset.xs="5px" fxLayout="row wrap" fxLayoutAlign="start center">

            <generali-badge *ngIf="article.arguments && article.arguments.length" type="third-tag"
              (click)="onCategoryClick($event, article.arguments[0])" [title]="article.arguments[0].title">
            </generali-badge>

            <ng-container *ngIf="article.arguments && article.arguments.length > 1">
              <generali-badge fxFlexOffset="15px" type="third-tag" title="…">
              </generali-badge>
            </ng-container>

          </div>

        </div>

      </div>

    </div>

  </div>

</div>