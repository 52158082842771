import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, OnInit, Input, Output, EventEmitter, HostListener, AfterViewInit, OnDestroy, Inject } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as smoothscroll from 'smoothscroll-polyfill';
import { LibraryStyle, LIBRARY_STYLE } from '../utils';

@Component({
  selector: 'generali-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class GeneraliCarouselComponent implements OnInit, AfterViewInit, OnDestroy {

  _titleTabs: string[];
  @Input() set titleTabs(value: string[]) {
    // In case is set in asyncronous
    this._titleTabs = value;
    if (!this._firstStartTimer) {
      this.ngAfterViewInit();
    }
  };

  @Input() manageSwipe: boolean = false;

  @Input() goToStartOnFinish: boolean = false;

  @Input() fromHero: boolean;

  _isLoading: boolean;
  @Input() set isLoading(value: boolean | string) {
    this._isLoading = coerceBooleanProperty(value);
    this.ngAfterViewInit();
  };

  /**
   * Hide the placeholder
   */
  _noPlaceholder: boolean;
  @Input() set noPlaceholder(value: boolean | string) {
    this._noPlaceholder = coerceBooleanProperty(value);
  };

  /**
   * Set an autoscroll every second
   */
  _autoScroll: boolean;
  @Input() set autoScroll(value: boolean | string) {
    this._autoScroll = coerceBooleanProperty(value);
  };

  /**
   * Time expressed in seconds for autoscroll
   */
  _autoScrollTime: number | undefined;
  @Input() set autoScrollTime(value: number) {
    this._autoScrollTime = value ? value : 20;
  };

  // Adapt the carousel to the full window
  @Input() isFullWindow: boolean = false;

  // If instead of line or buttons has dot tabs
  _hasDots: boolean;
  @Input() set hasDots(value: boolean | string) {
    this._hasDots = coerceBooleanProperty(value);
    if (!this._firstStartTimer) {
      this.ngAfterViewInit();
    }
  };

  // Avoid to show the navigation points
  _preventShowPoints: boolean;
  @Input('hidePoints') set preventShowPoints(value: boolean | string) {
    this._preventShowPoints = coerceBooleanProperty(value);
  };

  // Prevent scrolling
  _noScroll: boolean;
  @Input() set noScroll(value: boolean | string) {
    this._noScroll = coerceBooleanProperty(value);
  };

  // Prevent desktop scrolling
  _noScrollDesktop: boolean;
  @Input() set noScrollDesktop(value: boolean | string) {
    this._noScrollDesktop = coerceBooleanProperty(value);
  };

  @Input() noDataLabel: string = 'No results';
  @Input() preventOverflowMarginOnRight: boolean = false;

  @Input() sectionId: string;
  @Input() contentLength: number;

  @Input('scrollToLeft') scrollToLeft$: Observable<void> = new Observable();
  @Input('scrollToRight') scrollToRight$: Observable<void> = new Observable();

  // Emit the index page number of the scroll
  @Output() onScrollEvent = new EventEmitter<number>();

  // Just for the output of the index
  _currentIndex: number = 0;
  @Input()
  get index(): number {
    return this._currentIndex;
  };
  set index(value: number) {
    if (value !== undefined && value !== null && value !== this._currentIndex) {
      this.toIndex(value);
    }
  }
  @Output() indexChange = new EventEmitter<number>();

  resizeTimeout;

  private _unsubscribeSignal: Subject<void> = new Subject();

  private _screenWidth: number;
  private _elementWidth: number;

  private _indexToScroll: number = -1;

  private _previousScrollLeft: number = 0;

  // Sensibility in px
  private readonly _SCROLL_SENSE: number = 50;

  private _tabTimerInterval: NodeJS.Timeout;
  private scrollToIndexInterval;
  private widthCounter: number = 0;

  private _firstStartTimer: boolean;

  _timerStopped: boolean = false;

  _libraryStyle: LibraryStyle;

  isScrolling: boolean = false;
  /**
   * If the element is scrolling
   * @param scrollLeft Current left position
   * @param scrollDistance Scroll distance
  isScrolling = (scrollLeft: number, scrollDistance: number): boolean =>
    scrollLeft !== 0 && scrollLeft % scrollDistance !== 0;
   */

  /**
   * Get the current index of the visualized screen
   * @param scrollLeft Current left position
   * @param itemSize Item size in px
   */
  getCurrentIndex = (scrollLeft: number, itemSize: number): number =>
    scrollLeft === 0 ? 0 : Math.round(scrollLeft / itemSize);

  /**
   * Left scroll need to use floor - from 1.9 need to be 1
   * @param scrollLeft Current left position
   * @param itemSize Item size in px
   */
  getIndexOnScrollLeft = (scrollLeft: number, itemSize: number): number =>
    scrollLeft === 0 ? 0 : Math.floor(scrollLeft / itemSize);

  /**
   * Right scroll need to use ceil - from 1.1 need to be 2
   * @param scrollLeft Current left position
   * @param itemSize Item size in px
   */
  getIndexOnScrollRight = (scrollLeft: number, itemSize: number): number => Math.ceil(scrollLeft / itemSize);

  /**
   * Get the index based on the scroll direction
   *
   * @param scrollLeft Current left position
   * @param previousScrollLeft Previous left position
   * @param itemSize Item size in px
   */
  getIndexBasedOnScrollDirection = (scrollLeft: number, previousScrollLeft: number, itemSize: number): number =>
    scrollLeft > previousScrollLeft ?
      this.getIndexOnScrollRight(scrollLeft, itemSize) : this.getIndexOnScrollLeft(scrollLeft, itemSize);

  /**
   * Get the mobile tab
   * @param {number} index Index of the tab
   * @returns {HTMLElement} Mobile tab
   */
  getMobileTabFromIndex(index: number): HTMLElement {
    return document.getElementById(this.sectionId + '-mobile-tab' + index);
  }

  /**
   * Get the desktop tab
   * @param {number} index Index of the tab
   * @returns {HTMLElement} Desktop tab
   */
  getDesktopTabFromIndex(index: number): HTMLElement {
    return document.getElementById(this.sectionId + '-desktop-tab' + index);
  }

  get isInternetExplorerBrowser(): boolean {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf("MSIE ");
    return msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./);
  }

  get isSafariBrowser(): boolean {
    const ua = navigator.userAgent.toLocaleLowerCase();
    const mssafari = ua.indexOf("safari");
    return !(mssafari !== -1 && ua.indexOf("chrome") > -1);
  }

  /**
   * Get the number of bottom points for navigation
   */
  get points(): any[] {
    return new Array(this.contentLength);
  }

  /**
   * Section element
   */
  get wrappedElement(): HTMLElement {
    return document.getElementById(this.sectionId);
  }

  get isMobile(): boolean {
    return this._screenWidth && this._screenWidth < 600;
  }

  /**
   * Get the current mobile tab
   */
  get currentMobileTab(): HTMLElement {
    return this.getMobileTabFromIndex(this._currentIndex);
  }

  /**
   * Get the current desktop tab
   */
  get currentDesktopTab(): HTMLElement {
    return this.getDesktopTabFromIndex(this._currentIndex);
  }

  /**
   * Get the current mobile tab
   */
  get currentMobileButton(): HTMLElement {
    return document.getElementById(this.sectionId + '-mobile-button' + this._currentIndex);
  }

  /**
   * Get the current desktop tab
   */
  get currentDesktopButton(): HTMLElement {
    return document.getElementById(this.sectionId + '-desktop-button' + this._currentIndex);
  }

  constructor(@Inject(LIBRARY_STYLE) libraryStyle: LibraryStyle) {
    this._libraryStyle = libraryStyle;
  }

  /**
   * Adapt the sizes in case the window get resized
   */
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout((() => {
      this.setElementWidth();
    }).bind(this), 500);
  }

  ngOnInit() {
    this.sectionId = this.sectionId;
    this.contentLength = this.contentLength;
    smoothscroll.polyfill();

    this.scrollToLeft$
      .pipe(takeUntil(this._unsubscribeSignal.asObservable()))
      .subscribe(() => this.toIndex(this._currentIndex - (this._currentIndex === 0 ? 0 : 1)));

    this.scrollToRight$
      .pipe(takeUntil(this._unsubscribeSignal.asObservable()))
      .subscribe(() => {
        if (((this._currentIndex + 1) >= this.contentLength) && this.goToStartOnFinish) {
          this.toIndex(0);
        } else {
          this.toIndex(this._currentIndex + ((this._currentIndex + 1) >= this.contentLength ? 0 : 1))
        }
      }
      );
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.setElementWidth();

      if (this._autoScroll && !this._isLoading) {
        if (this._titleTabs && this._titleTabs.length > 1) {
          this._firstStartTimer = true;
          this.startTabTimer();
        } else if (this._hasDots && this.contentLength) {
          this._firstStartTimer = true;
          this.startDotsTimer();
        }
      }
    }, 100);
  }

  //#region Dots timer

  startStopDotTimer() {
    if (this._timerStopped) {
      this.startDotsTimer();
      this._timerStopped = false;
    } else {
      clearTimeout(this._tabTimerInterval);
      this._timerStopped = true;
    }
  }

  private startDotsTimer() {
    clearTimeout(this._tabTimerInterval);
    this._tabTimerInterval = setTimeout(() => {
      if (!this._timerStopped) {
        const newIndex = this._currentIndex + 1;
        if (newIndex === this.contentLength) {
          // Sono arrivato alla fine, quindi reinizio il giro
          this.toIndexNoSmooth(0);
        } else {
          this.toIndex(newIndex);
        }
      }
    }, (this._autoScrollTime * 1000));
  }

  /**
   * On a click dot
   * @param dotIndex Index of the clicked dot
   */
  onDotClick(dotIndex: number) {
    // Resetto l'interval
    clearTimeout(this._tabTimerInterval);
    this.toIndex(dotIndex);
  }

  //#endregion

  //#region Tabs timer

  startStopTimer() {
    if (this._timerStopped) {
      let timeout = setTimeout(() => {
        clearTimeout(timeout)
        this._timerStopped = false;
      }, 0);
    } else {
      let timeout = setTimeout(() => {
        clearTimeout(timeout)
        this._timerStopped = true;
      }, 0);
    }
  }

  isSmartEnergy() {
    return this._libraryStyle && this._libraryStyle === 'SMART_ENERGY'
  };

  private startTabTimer() {
    this.widthCounter = 0;

    clearInterval(this._tabTimerInterval);
    this._tabTimerInterval = setInterval(() => {

      if (!this._timerStopped) {

        let currentTab: HTMLElement = this.currentDesktopTab;
        let currentButton: HTMLElement = this.currentDesktopButton;

        // Aggiorno lo stile della linea con la nuova grandezza

        currentTab = this.currentMobileTab;
        currentButton = this.currentMobileButton;


        // Recupero la grandezza della tab per vedere se sono alla fine
        let tabButtonLength = 282;
        if (currentButton) {
          // Tolgo l'eventuale padding
          const buttonStyle: CSSStyleDeclaration = window.getComputedStyle(currentButton);
          const paddingRight = buttonStyle.paddingRight ? parseFloat(buttonStyle.paddingRight) : 0;
          const paddingLeft = buttonStyle.paddingLeft ? parseFloat(buttonStyle.paddingLeft) : 0;
          tabButtonLength = currentButton.offsetWidth - paddingRight - paddingLeft;
        }

        // Ad ogni secondo aggiungo un tot di px alla larghezza
        const pxToAdd = tabButtonLength / this._autoScrollTime;
        const newLineWidth = this.widthCounter + pxToAdd + "px";

        // Aggiorno il contatore
        this.widthCounter = this.widthCounter + pxToAdd;

        // Se l'onDestroy non venisse chiamato con un cambio pagina
        if (currentTab) {
          currentTab.style.width = newLineWidth;
          currentTab.style.maxWidth = tabButtonLength + "px";
        } else {
          clearInterval(this._tabTimerInterval);
        }

        // Go to the new tab
        if (currentTab && this.widthCounter > tabButtonLength) {
          clearInterval(this._tabTimerInterval);
          if (currentTab) {
            currentTab.style.width = '0px';
            this.widthCounter = 0;
            const newIndex = this._currentIndex + 1;
            if (newIndex === this._titleTabs.length) {
              // Sono arrivato alla fine, quindi reinizio il giro
              this.toIndexNoSmooth(0);
            } else {
              this.toIndex(newIndex);
            }
          }
        }

      }

    }, 1000);
  }

  /**
   * Clear the timered tabs
   */
  private clearTimedTabs(onlyTabs?: boolean) {
    if (!onlyTabs) {
      clearInterval(this._tabTimerInterval);
    }

    this.widthCounter = 0;
    if (this._titleTabs && this._titleTabs.length > 1) {
      /* if (this.isMobile) { */
      this._titleTabs.forEach((tab, index) => this.getMobileTabFromIndex(index).style.width = '0px');
      /* } else {
        this._titleTabs.forEach((tab, index) => this.getDesktopTabFromIndex(index).style.width = '0px');
      } */
    }
  }

  swipeLeft(event?) {
    if (this.manageSwipe) {
      const newIndex = this._currentIndex + 1;
      this.clearTimedTabs();
      this.toIndex(newIndex);
    }
  }

  swipeRight(event?) {
    if (this.manageSwipe) {
      const newIndex = this._currentIndex - 1;
      this.clearTimedTabs();
      this.toIndex(newIndex);
    }
  }

  /**
   * On a click tab
   * @param tabIndex Index of the clicked tab
   */
  onTabClicked(tabIndex: number) {
    // Resetto l'interval
    this.clearTimedTabs();
    if (tabIndex == this._titleTabs.length) {
      tabIndex = 0;
    }
    this.toIndex(tabIndex);
  }

  //#endregion

  ngOnDestroy() {
    if (this._tabTimerInterval) {
      clearInterval(this._tabTimerInterval);
    }
    if (this.scrollToIndexInterval) {
      clearInterval(this.scrollToIndexInterval);
    }
    this._unsubscribeSignal.next();
    this._unsubscribeSignal.unsubscribe();
  }

  /**
   * Set the element width to scroll
   */
  setElementWidth() {
    if (this.fromHero) {
      this._screenWidth = document.documentElement.clientWidth; // Client width è la larghezza dello schermo meno la width della scrollbar
    } else {
      this._screenWidth = window.innerWidth; // E' la larghezza dello schermo con la width della scrollbar
    }

    let elementWidth;
    if (this.isFullWindow) {
      elementWidth = this._screenWidth;
    } else {
      const wrappedElement: HTMLElement = this.wrappedElement;
      // Calcolo la larghezza del singolo elemento
      if (wrappedElement) {
        const scrollWidth: number = wrappedElement.scrollWidth;
        elementWidth = scrollWidth / this.contentLength;
        // console.log(this.sectionId, "elementWidth", this._elementWidth);
      } else {
        elementWidth = 0;
      }
    }
    if (this._elementWidth !== elementWidth) {
      this.toIndex(this._currentIndex);
      this._elementWidth = elementWidth;
    } else {
      this._elementWidth = elementWidth;
    }
  }

  /**
   * Quando viene eseguito lo scroll
   * @param event Evento di scroll
   */
  onScroll(event?: Event) {
    event.stopPropagation();
    event.preventDefault();
    if (this.isScrolling) {
      return;
    }
    this.isScrolling = true;

    const wrappedElement: HTMLElement = this.wrappedElement;

    if (wrappedElement) {
      // Posizione corrente al momento dello scroll
      const scrollLeft: number = wrappedElement.scrollLeft;

      // Next index based on direction
      const nextIndex = this.getIndexBasedOnScrollDirection(scrollLeft, this._previousScrollLeft, this._elementWidth);

      // Current index - next index per verificare se lo scroll è da touch o da click
      if (Math.abs(scrollLeft - this._previousScrollLeft) > this._SCROLL_SENSE) {
        this._previousScrollLeft = scrollLeft;

        if (this._indexToScroll === -1 && this._currentIndex !== nextIndex) {
          // Current index here because the scroll event
          // is faster then scrollToIndex function
          this._currentIndex = nextIndex;

          this.scrollToIndex(nextIndex);
        }

        // Timeout to avoid bounce back on scroll event from
        // forced go to index of buttons
        let timeout = setTimeout(() => {
          if (this._indexToScroll === this._currentIndex) {
            this._indexToScroll = -1;
          }
          clearInterval(timeout);
        }, 500);

        let scrollingTimeout = setTimeout(() => {
          this.isScrolling = false;
          clearInterval(scrollingTimeout);
        }, 1000)

      }
    } else {
      this.isScrolling = false;
    }
  }

  /**
   * Used for programmatic scroll
   * @param index Index to scroll
   */
  toIndex(index: number) {
    this._indexToScroll = index;
    this.scrollToIndex(index);

    // Resetto il counter dell'autoscroll (se previsto)
    if (this._autoScroll && this._titleTabs && this._titleTabs.length > 1) {
      this.startTabTimer();
    } else if (this._autoScroll && this._hasDots && this.contentLength) {
      this.startDotsTimer();
    }
  }

  /**
   * Go to the index without a smooth effect
   * @param index Index to scroll
   */
  toIndexNoSmooth(index: number) {
    this._indexToScroll = index;
    this.scrollToIndex(index, true);

    // Resetto il counter dell'autoscroll (se previsto)
    if (this._autoScroll && this._titleTabs && this._titleTabs.length > 1) {
      this.startTabTimer();
    }
  }

  /**
   * Request to scroll to a specific index
   * @param elementId Element id
   * @param index Index to scroll
   */
  scrollToIndex(index: number, noSmooth?: boolean) {
    // Recupero l'elemento in cui c'è lo scroll
    const wrappedElement: HTMLElement = this.wrappedElement;
    if (wrappedElement) {

      const previousIndex = this._currentIndex;

      this._currentIndex = index;

      clearInterval(this.scrollToIndexInterval);
      this.scrollToIndexInterval = setTimeout(() => {

        if (this.wrappedElement) {

          let newLeft: number = this._elementWidth * index;

          // this._indexToScroll !== -1 means the scroll is forced
          // if (this._indexToScroll !== -1) {
          // If previousIndex < index is scrolling to the right
          // Force to go to last index or first in case the item is smaller then the container
          if (previousIndex < index) {
            if ((newLeft + this.wrappedElement.offsetWidth) >= this.wrappedElement.scrollWidth) {
              // console.log("Recalculate index – DX");
              this._currentIndex = this._indexToScroll = index = this.contentLength - 1;
              newLeft = this._elementWidth * index;
              // console.log('scrollToIndex _1_: ' + this._currentIndex);
              this.indexChange.emit(this._currentIndex);
            }
          } else {
            // If i'm to the last index i recalculate it
            if (!this.isFullWindow && previousIndex === this.contentLength - 1) {
              // console.log("Recalculate index – SX");
              this._currentIndex = this._indexToScroll = index =
                previousIndex - Math.round(this.wrappedElement.offsetWidth / this._elementWidth);
              newLeft = this._elementWidth * index;
              // console.log('scrollToIndex _2_: ' + this._currentIndex);
              this.indexChange.emit(this._currentIndex);
            }
          }
          /*  } else {
              // If this._previousScrollLeft < newLeft is scrolling to the right
              if (this._previousScrollLeft < newLeft) {
                // For update the index when there is a natural scroll to the right
                // To the left is already calculated from onScroll
                if (previousIndex + Math.round(this.wrappedElement.offsetWidth / this._elementWidth) === this.contentLength) {
                  // console.log("Recalculate index – DX");
                  this._currentIndex = this._indexToScroll = index = this.contentLength - 1;
                  newLeft = this._elementWidth * index;
                  // console.log('scrollToIndex _3_: ' + this._currentIndex);
                  this.indexChange.emit(this._currentIndex);
                }
              } else {
                // console.log('scrollToIndex _4_: ' + this._currentIndex);
                this.indexChange.emit(this._currentIndex);
              }
            } */

          this.onScrollEvent.emit(this._currentIndex);

          // console.log("previousIndex", previousIndex, "index", index, previousIndex < index ? "right" : "left");

          this.clearTimedTabs(true);

          if (this.isInternetExplorerBrowser || this.isSafariBrowser) {
            wrappedElement.scrollLeft = newLeft;
          } else {
            if (noSmooth) {
              wrappedElement.scrollTo({
                left: newLeft
              });
            } else {
              wrappedElement.scrollTo({
                left: newLeft,
                behavior: 'smooth'
              });
            }
          }
        }

        clearTimeout(this.scrollToIndexInterval);
      }, 0);

    }
  }

}
