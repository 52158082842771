import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';

import { BlogPost } from 'atfcore-commonclasses/bin/classes/blog';
import { TagInfo } from 'atfcore-commonclasses/bin/classes/tag';

import { LibraryStyle, LIBRARY_STYLE } from '../utils';

@Component({
  selector: 'generali-home-blog',
  templateUrl: './home-blog.component.html',
  styleUrls: ['./home-blog.component.scss']
})
export class GeneraliHomeBlogComponent {

  _articlesLength: number;
  _mainArticle?: BlogPost;
  _otherArticles: BlogPost[] = [];
  @Input() set articles(value: BlogPost[]) {

    if (value && value.length) {
      this._articlesLength = value.length;
      this._hideViewAll = value.length < 2;
      this._mainArticle = value[0];
      if (value.length > 1) {
        this._otherArticles = value.splice(1, 3);
      }
    } else {
      this._articlesLength = 0;
      this._mainArticle = undefined;
      this._otherArticles = [];
    }
    if(this._mainArticle && this._mainArticle.description) {
      this._mainArticle.description = this.decodeHtml(this._mainArticle.description);
    }
  }

  @Input() title: string = '';

  @Input() defaultImage?: string;

  @Input() isLoading: boolean = true;

  @Input() articleCount?: number;

  @Output() viewAllTap = new EventEmitter<void>();

  @Output() categoryClick = new EventEmitter<TagInfo>();

  @Output() onClick = new EventEmitter<BlogPost>();

  _libraryStyle: LibraryStyle;

  _hideViewAll: boolean = false;

  get isSmartEnergy(): boolean { return this._libraryStyle === 'SMART_ENERGY' };

  constructor(@Inject(LIBRARY_STYLE) libraryStyle: LibraryStyle) {
    this._libraryStyle = libraryStyle;
  }

  ngOnInit() {

  }

  onCategoryClick(event: Event, category: TagInfo) {
    event.stopPropagation();
    this.categoryClick.emit(category);
  }

  decodeHtml(html) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }

  onArticleClick(article: BlogPost) {
    this.onClick.emit(article);
  }

  trackByTagId(index: any, item: any) {
    return item.tagId;
  }

  trackByItemId(index: any, item: any) {
    return item.itemId;
  }

}
