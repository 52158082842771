<div [id]="sectionId + '-container'" class="generali-carousel" [ngClass]="_libraryStyle">
  <!-- slider card corsi suggeriti -->
  <div class="section-content-wrapper" [ngClass]="{'full-window-content-wrapper': isFullWindow}" fxLayout="row">

    <!-- Wrapper del carosello -->
    <div class="generali-carousel-content-wrapper">
      <!-- Wrapper risultati -->
      <div [id]="sectionId" class="generali-carousel-slider-wrapper" fxLayout="row" fxLayoutAlign="start start"
        (swipeleft)="swipeLeft()" (swiperight)="swipeRight()"
        [ngClass.gt-xs]="{'no-scroll': _noScrollDesktop || _noScroll}" [ngClass.xs]="{'no-scroll': _noScroll, 'overflowMarginOnRight' : !preventOverflowMarginOnRight,
      'touchEventAuto' : !manageSwipe }">

        <ng-content></ng-content>

        <div fxShow fxHide.xs *ngIf="_hasDots && contentLength && !_preventShowPoints" [id]="sectionId + '-dots'"
          class="generali-carousel-dots" fxLayout="row" fxLayoutAlign="start center">

          <div class="generali-carousel-button-padding"></div>

          <!-- Puntini di posizione -->
          <div *ngFor="let point of points; let index = index; let first = first" (click)="onDotClick(index)"
            class="generali-carousel-dot" [ngClass]="{'current-dot': index === _currentIndex}"
            [fxFlexOffset]="first ? '0px' : '14px'"></div>

          <!-- Start and Stop -->
          <ng-container *ngIf="_autoScroll && _titleTabs.length > 1">
            <!-- Start -->
            <svg-icon *ngIf="_timerStopped" fxFlexOffset="14px"
              src="assets/icons/icon-start-{{isSmartEnergy() ? 'smart' : 'go'}}.svg" class="start-icon"
              [applyClass]="true" (click)="startStopDotTimer()">
            </svg-icon>

            <!-- Stop -->
            <svg-icon *ngIf="!_timerStopped" fxFlexOffset="6px"
              src="assets/icons/icon-stop-{{isSmartEnergy() ? 'smart' : 'go'}}.svg" class="stop-icon dots"
              [applyClass]="true" (click)="startStopDotTimer()">
            </svg-icon>
          </ng-container>
        </div>

        <div *ngIf="_titleTabs && _titleTabs.length > 1 && !_preventShowPoints" [id]="sectionId + '-points'"
          class="generali-carousel-points" [ngClass]="{'width-auto-with-height': isSmartEnergy()}" fxLayout="row" fxLayoutAlign="space-between">

          <div class="generali-carousel-button-padding"></div>

          <div ngClass.xs="width-55" class="generali-carousel-button-padding additional-padding width-85" fxLayout="row" fxLayoutAlign="start start">
            <!-- Pulsante Start and Stop -->
            <div *ngIf="_autoScroll && _titleTabs.length > 1" class="generali-carousel-start-and-stop" fxLayout="row"
              fxLayoutAlign="center center" (click)="startStopTimer()">
              <!-- Start -->
              <svg-icon *ngIf="_timerStopped" fxFlexOffset="1px"
                src="assets/icons/icon-start-{{isSmartEnergy() ? 'smart' : 'go'}}.svg" class="start-icon"
                [applyClass]="true"></svg-icon>
              <!-- Stop -->
              <svg-icon *ngIf="!_timerStopped" src="assets/icons/icon-stop-{{isSmartEnergy() ? 'smart' : 'go'}}.svg"
                class="stop-icon" [applyClass]="true"></svg-icon>
            </div>
          </div>

          <div fxLayout="row" fxLayoutAlign="start start" class="full-width">
            <div [id]="sectionId + '-mobile-button' + index" [fxFlexOffset]="first ? '0px' : '14px'"
              *ngFor="let point of points; let index = index; let first = first" (click)="onTabClicked(index)"
              class="generali-carousel-point" fxFlex>
              <div [id]="sectionId + '-mobile-tab' + index" [ngClass]="{'current-point': index === _currentIndex, 'in-smart-energy': isSmartEnergy()}">
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="_titleTabs && _titleTabs.length > 1" class="arrows-desktop-container" fxLayout="row"
          fxLayoutAlign="start center" [ngClass]="{'bottom-smart-energy flex-hide-lt-md': isSmartEnergy(), 'flex-hide-xs': !isSmartEnergy()}">
          <div class="generali-carousel-button-padding"></div>
          <div fxFlex fxLayout="row" fxLayoutAlign="start start">
            <svg-icon *ngIf="_currentIndex != 0" (click)="onTabClicked(_currentIndex - 1)"
              class="margin-right25 cursor-pointer" src="assets/icons/navigation-arrows-left.svg"></svg-icon>
            <svg-icon *ngIf="_currentIndex == 0" class="margin-right25 cursor-default"
              src="assets/icons/navigation-arrows-left-disabled.svg"></svg-icon>
            <svg-icon class="cursor-pointer"
              (click)="onTabClicked(_currentIndex + 1)" src="assets/icons/navigation-arrows-right.svg"></svg-icon>
            <!-- <svg-icon *ngIf="(_currentIndex + 1) == _titleTabs.length" class="cursor-default"
              src="assets/icons/navigation-arrows-right-disabled.svg"></svg-icon> -->
          </div>
        </div>

        <!-- Tab aggiuntive, come quelle nel banner in home -->
        <!-- <div *ngIf="_titleTabs && _titleTabs.length" class="generali-carousel-buttons flex-hide-xs" fxLayout="row"
          fxLayoutAlign="start center">

          <div class="generali-carousel-button-padding"></div>

          <div fxFlex fxLayout="row" fxLayoutAlign="center center">

            <div *ngFor="let titleTab of _titleTabs; let index = index" [id]="sectionId + '-desktop-button' + index"
              fxLayout="column" fxLayoutAlign="center center" (click)="onTabClicked(index)"
              class="generali-carousel-button" [ngClass]="{ 'current-point': index === _currentIndex }"
              [fxFlexOffset]="index === 0 ? '0' : '26px'">

              <h5>{{ titleTab | translate}}</h5>

              <div [hidden]="index !== _currentIndex" [fxFlexOffset]="isSmartEnergy() ? '16px' : '8px'"
                class="generali-carousel-button-line" [id]="sectionId + '-desktop-tab' + index">
              </div>

            </div>

          </div>

          <div class="generali-carousel-button-padding" fxLayout="row">
            <div *ngIf="_autoScroll && _titleTabs.length > 1" class="generali-carousel-start-and-stop" fxLayout="row"
              fxLayoutAlign="center center" (click)="startStopTimer()">
              <svg-icon *ngIf="_timerStopped" fxFlexOffset="3px"
                src="assets/icons/icon-start-{{isSmartEnergy() ? 'smart' : 'go'}}.svg" class="start-icon"
                [applyClass]="true"></svg-icon>
              <svg-icon *ngIf="!_timerStopped" src="assets/icons/icon-stop-{{isSmartEnergy() ? 'smart' : 'go'}}.svg"
                class="stop-icon" [applyClass]="true"></svg-icon>
            </div>
          </div>

          <div>

          </div>

        </div> -->

      </div>
      <!-- Placeholder nessun risultato -->
      <div *ngIf="!isLoading && contentLength === 0 && !_noPlaceholder">
        <img alt="empty" src="assets/icons/empty.svg" />
        <div tabindex="0" class="empty-title">
          {{ noDataLabel }}
        </div>
      </div>
    </div>
  </div>
</div>
