import { DatePipe } from '@angular/common';
import { Component, Input, OnInit, Inject, Output, EventEmitter } from '@angular/core';

import { BlogPost } from "atfcore-commonclasses/bin/classes/blog";
import { CarouselCourseItem } from "atfcore-commonclasses/bin/classes/talent-farm";
import { OnDemandItem } from "atfcore-commonclasses/bin/classes/onDemandItem";
import { WebinarSession, JobInformation, EventOrWebinarSession } from "atfcore-commonclasses/bin/classes/coursemanager";
import { TagInfo } from 'atfcore-commonclasses/bin/classes/tag';

import { BadgeType } from '../badge/badge.component';
import {
  instanceOfBlogPost,
  instanceOfCategory,
  instanceOfChallenge,
  instanceOfCarouselCourseItem,
  instanceOfJobInformation,
  instanceOfOnDemandItem,
  instanceOfWebinarSession,
  LibraryStyle,
  LIBRARY_STYLE,
  checkArguments,
} from '../utils';
import { CategoryCardInterface, ChallengeCardInterface } from '../interfaces';
import { ItemTakerEnrollStatusTypes } from 'atfcore-commonclasses/bin/classes/item';
import * as moment from 'moment';

export type CardType =
  | 'live-event'
  | 'premium'
  | 'badge'
  | 'on-demand-event'
  | 'general-event'
  | 'course'
  | 'job'
  | 'challenge'
  | 'blog'
  | 'live-event-top'
  | 'top-ten-courses'
  | 'category'
  | 'challenge-team'
  | 'web-collection'
  | 'playlist-item'
  | 'item-of-web-collection'
  | 'library-item'
  | 'cm2-course'
  | 'user-course';

@Component({
  selector: 'generali-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class GeneraliCardComponent implements OnInit {
  _type: CardType;
  @Input() set type(value: CardType) {
    if (value) {
      this._type = value;
      // In case type input is declared after card input
      if (this._card) {
        this.card = this._card;
      }
    } else {
      throw 'Type required in card component';
    }
  }

  _card: any;
  @Input() set card(value: any) {
    if (value) {
      switch (this._type) {
        case 'live-event':
          this.setLiveEventCard(value);
          break;
        case 'live-event-top':
          this.setLiveEventCard(value, true);
          break;
        case 'top-ten-courses':
          this.setTopTenCourses(value);
          break;
        case 'on-demand-event':
          this.setOnDemandEventCard(value);
          break;
        case 'general-event':
          this.setCourseCard(value, true);
          break;
        case 'course':
          this.setCourseCard(value);
          break;
        case 'job':
          this.setJobInformationCard(value);
          break;
        case 'challenge':
          this.setChallengeCard(value);
          break;
        case 'blog':
          this.setBlogPostCard(value);
          break;
        case 'category':
          this.setCategoryCard(value);
          break;
        case 'premium':
          this.setCourseCard(value);
          break;
        case 'web-collection':
          this.setCollectionCard(value);
          break;
        case 'item-of-web-collection':
          this.setItemOfWebCollectionCard(value);
          break;
        case 'playlist-item':
          this.setPlaylistCard(value);
          break;
        case 'cm2-course':
          this.setCm2Course(value);
          break;
        case 'library-item':
          this.setLibraryItem(value);
          break;
      }
    }

    this._card = value ? value : {};
  }

  _preventCollectionForYouWaves: boolean;
  @Input() set preventCollectionForYouWaves(value: any) {
    this._preventCollectionForYouWaves = value;
  }

  _translatedCourseType: string;
  @Input() set translatedCourseType(value: any) {
    this._translatedCourseType = value;
  }

  _isBookmarked: boolean;
  @Input() set isBookmarked(value: any) {
    this._isBookmarked = value;
  }

  _isSwitchingBookmark: boolean;
  @Input() set isSwitchingBookmark(value: any) {
    this._isSwitchingBookmark = value;
  }

  /**
   * Required for Top ten live event
   */
  @Input() cardNumber: number | undefined;

  @Input() isCarouselOfPrems: boolean;

  /**
   * If true the tooltip is showed
   */
  @Input() showTooltip: boolean;

  /**
   * If set override the default image in case the main image is missing
   */
  @Input() defaultImage: string | undefined;

  /**
   * View all label
   */
  @Input() viewAllLabel: string | undefined;
  @Input() isShowAll: boolean;

  @Input() isVetrin: boolean;

  /**
   * Required isShowAll
   */
  @Input() viewAllNumber: number = 0;

  @Input() femaleUser: boolean = false;

  @Input() withPremiumMobileWidth: boolean = false;

  /** Se true, l'aspetto della card cambia se l'evento è concluso */
  @Input() distinctForPastEvents?: boolean;

  /** Se true, il titolo della playlist verrà tagliato se oltre una riga */
  @Input() titleOnlyOneRow?: boolean;

  @Output() onTagClick: EventEmitter<any> = new EventEmitter();
  @Output() onToggleBookmark: EventEmitter<any> = new EventEmitter();
  @Output() onCardClick: EventEmitter<any> = new EventEmitter();

  _title: string;
  _coverImage: string;
  _percentage: number;
  _location: string;
  _company: string = "";

  _hasApplied: boolean;

  _enumerationSerie: string = '';
  _serieTitle: string = '';

  _showBadge: boolean;
  _badgeTitle: string;
  _badgeType: BadgeType;

  _arguments: TagInfo[] = [];
  _argumentsMobile: TagInfo[] = [];

  _courseType: string;
  _userStatus: string;
  _where: string;
  _when: string;
  _iconSrc: string;

  _timing: string;
  _minutes: number;
  _hasDuration: boolean;
  _isInliveNow: boolean;
  _isEnrolled: boolean;
  _isWebinarEnrolled: boolean;
  _isEventEnrolled: boolean;
  _isCompleted: boolean;

  _isEndEnrollDate: boolean;
  _availableSeats: number;

  _libraryStyle: LibraryStyle;

  _multipleEditionsEventData: {
    otherEditionsCount: number;
    otherLocationsCount: number;
  }

  _eventEnded: boolean;

  isTopLiveEvent(): boolean { return this._type === 'live-event-top' };
  isTopTenCourses(): boolean { return this._type === 'top-ten-courses' };
  isLiveEvent(): boolean { return this._type === 'live-event' };
  isOnDemandEvent(): boolean { return this._type === 'on-demand-event' };
  isGeneralEvent(): boolean { return this._type === 'general-event' };
  isEvent(): boolean { return this.isGeneralEvent() || this.isOnDemandEvent() || this.isLiveEvent() || this.isTopLiveEvent() };
  isCourse(): boolean { return this._type === 'course' };
  isArticle(): boolean { return this._type === 'blog' };
  isPrem(): boolean { return this._type === 'premium' };
  isCategory(): boolean { return this._type === 'category' };
  isJob(): boolean { return this._type === 'job' };
  isChallenge(): boolean { return this._type === 'challenge' };
  hasLive(): boolean { return this.isLiveEvent() || this.isTopLiveEvent() };
  hasSubscription(): boolean {
    return this.isLiveEvent() || this.isTopLiveEvent() || this.isGeneralEvent() || this.isCourse();
  }
  isWebCollection(): boolean {
    return this._type === 'web-collection';
  }
  isItemOfWebCollection(): boolean {
    return this._type === 'item-of-web-collection';
  }
  isPlaylistItem(): boolean {
    return this._type === 'playlist-item';
  }
  isSmartEnergy(): boolean {
    return this._libraryStyle === 'SMART_ENERGY';
  }
  isCm2Course(): boolean {
    return this._type === 'cm2-course';
  }
  isLibraryItem(): boolean {
    return this._type === 'library-item';
  }

  isPastEvent(): boolean { return this.isEvent() && this._eventEnded }

  constructor(
    private datePipe: DatePipe,
    @Inject(LIBRARY_STYLE) libraryStyle: LibraryStyle
  ) {
    this._libraryStyle = libraryStyle;
  }

  ngOnInit() {
  }

  private showLogError() {
    console.error('Card type ' + this._type + ' wrong data - card component');
  }

  private setLiveEventCard(value: any, topTen?: boolean) {
    // Check if is a WebinarSession type
    if (!instanceOfWebinarSession(value) && !this.isSmartEnergy()) {
      this.showLogError();
    } else {
      // Validation for card number
      if (topTen && !this.cardNumber && !this.isShowAll) {
        console.error('Card type ' + this._type + ' cardNumber required');
      }

      this._title = value.title;
      this._coverImage = value.cardCover || value.coverImage || this.defaultImage || 'assets/img/pl.png';
      if (this.hasLive() && this.isSmartEnergy()) {
        this._isWebinarEnrolled = value && value.webinarSession && value.webinarSession.isUserEnrolled;
        this._isEventEnrolled = value && value.eventSession && value.eventSession.isUserEnrolled;
        this._location = value && value.eventSession && value.eventSession.location || null;
        this._availableSeats = (value.eventSession && value.eventSession.availableSeats || 0) + (value.webinarSession && value.webinarSession.availableSeats || 0);
      } else {
        this._isEnrolled = value.isUserEnrolled;
        /* this._isEnrolled = value.isUserCompleted; */
        this._availableSeats = value.availableSeats;
      }
      this._isEndEnrollDate = value.endEnrollDate ? new Date(value.endEnrollDate).getTime() <= new Date().getTime() : false;

      // Calculate the percentage time of live event
      if (value.startDate && value.endDate) {
        const startDate = new Date(value.startDate).getTime();
        const endDate = new Date(value.endDate).getTime();
        // const currentDate = new Date(2021, 3, 2, 17, 55, 0, 0).getTime();
        const currentDate = new Date().getTime();

        let minutePassedAfterStart;

        if (startDate > currentDate) {
          this._percentage = 0;
        } else if (endDate < currentDate) {
          this._percentage = 100;
        } else {
          this._isInliveNow = true;
          const durationInMinutes = (endDate - startDate) / 60000;
          minutePassedAfterStart = Math.trunc(
            (currentDate - startDate) / 60000
          );
          // passed time : x = duration : 100
          this._percentage = (minutePassedAfterStart * 100) / durationInMinutes;
        }

        if (topTen) {
          this._percentage = undefined;
        }

        if (this._isInliveNow) {
          this._minutes = minutePassedAfterStart;
        } else {
          if (startDate) {
            this._timing = this.datePipe.transform(
              startDate,
              'dd MMMM ore HH:mm/'
            );
          }
          if (endDate) {
            if (!this._timing) {
              this._timing = '';
            }
            this._timing += this.datePipe.transform(endDate, 'HH:mm');
          }
        }
      }

      if (value.arguments && value.arguments.length) {
        this._arguments = checkArguments(value.arguments, topTen ? 1 : 3);
        this._argumentsMobile = checkArguments(value.arguments, 1);
      }
    }
  }

  private setTopTenCourses(value) {
    this._arguments = value.arguments;
    this._coverImage = value.coverImage;
    this._when = value.courseDuration;
    this._courseType = value.courseType;
    this._userStatus = value.userStatus;
    this._iconSrc = value.iconSrc;
    this._where = value.where;

    this._title = value.title;
    this._coverImage = value.cardCover ||
      value.coverImage || this.defaultImage || 'assets/img/pl.png';
    this._isEnrolled = value.isUserEnrolled;
    /* this._isEnrolled = value.isUserCompleted; */
    this._availableSeats = value.availableSeats;
    this._isEndEnrollDate = value.endEnrollDate
      ? new Date(value.endEnrollDate).getTime() <= new Date().getTime()
      : false;

    // Calculate the percentage time of live event
    if (value.startDate && value.endDate) {
      const startDate = new Date(value.startDate).getTime();
      const endDate = new Date(value.endDate).getTime();
      // const currentDate = new Date(2021, 3, 2, 17, 55, 0, 0).getTime();
      const currentDate = new Date().getTime();

      let minutePassedAfterStart;

      if (startDate > currentDate) {
        this._percentage = 0;
      } else if (endDate < currentDate) {
        this._percentage = 100;
      } else {
        this._isInliveNow = true;
        const durationInMinutes = (endDate - startDate) / 60000;
        minutePassedAfterStart = Math.trunc(
          (currentDate - startDate) / 60000
        );
        // passed time : x = duration : 100
        this._percentage = (minutePassedAfterStart * 100) / durationInMinutes;
      }

      if (this._isInliveNow) {
        this._minutes = minutePassedAfterStart;
      } else {
        if (startDate) {
          this._timing = this.datePipe.transform(
            startDate,
            'dd MMMM ore HH:mm/'
          );
        }
        if (endDate) {
          if (!this._timing) {
            this._timing = '';
          }
          this._timing += this.datePipe.transform(endDate, 'HH:mm');
        }
      }
    }

    if (value.arguments && value.arguments.length) {
      this._arguments = checkArguments(value.arguments, 1);
      this._argumentsMobile = checkArguments(value.arguments, 1);
    }
  }

  private setOnDemandEventCard(value: OnDemandItem) {
    // Check if is a OnDemandItem type
    if (!instanceOfOnDemandItem(value)) {
      this.showLogError();
    } else {
      this._title = value.title;
      this._coverImage =
        value.itemImage || this.defaultImage || 'assets/img/pl.png';

      this._minutes = value.durationInMinutes;
      this._hasDuration = this._minutes && this._minutes > 0;

      this._percentage = value.currentPrecentage;
      if (
        value.collectionInContainerPosition &&
        value.itemInCollectionPosition
      ) {
        this._enumerationSerie = `S${value.collectionInContainerPosition}:E${value.itemInCollectionPosition}`;
      }

      if (value.containerTitle) {
        this._serieTitle = value.containerTitle + ' ';
      }

      if (value.arguments && value.arguments.length) {
        this._arguments = checkArguments(value.arguments, 3);
        this._argumentsMobile = checkArguments(value.arguments, 1);
      }
    }
  }

  private setCollectionCard(value) {
    this._title = value.title;
    this._coverImage = value.coverImage || value.cardCover ||
      value.itemImage || this.defaultImage || 'assets/img/pl.png';
    if (value.arguments && value.arguments.length) {
      this._arguments = checkArguments(value.arguments, 3);
      this._argumentsMobile = checkArguments(value.arguments, 1);
    }
  }

  private setPlaylistCard(value) {
    this._title = value.title;
    this._coverImage = value.coverImage || value.cardCover ||
      value.itemImage || value.coverImage || this.defaultImage || 'assets/img/pl.png';
    if (value.arguments && value.arguments.length) {
      this._arguments = checkArguments(value.arguments, 3);
      this._argumentsMobile = checkArguments(value.arguments, 1);
    }
  }

  private setItemOfWebCollectionCard(value) {
    this._title = value.title || value.courseTitle || '';
    this._coverImage =
      value.itemImage || value.cardCover || value.coverImage || value.courseImage || this.defaultImage || 'assets/img/pl.png';
    /* if (value && value.argumentName && value.argumentName.length) {
      const newArgument: any = [{ tagId: value.argumentName, title: value.argumentName }];
      this._arguments = newArgument;
    } */

    if (value.courseCategories && value.courseCategories.length) {
      value.courseCategories = [value.courseCategories[0]];
      value.courseCategoriesMobile = [value.courseCategories[0]];
    }
  }

  private setBlogPostCard(value: BlogPost) {
    // Check if is a BlogPost type
    if (!instanceOfBlogPost(value)) {
      this.showLogError();
    } else {
      this._title = value.title;
      this._coverImage =
        value.postImage || this.defaultImage || 'assets/img/pl.png';
      let creationDate;

      if (this.isSmartEnergy()) {
        if (value.publicationDate) {
          creationDate = new Date(value.publicationDate).getTime();
        } else {
          creationDate = new Date(value.creationDate).getTime();
        }
      } else {
        creationDate = new Date(value.modifyDate).getTime();
      }
      this._timing = this.datePipe.transform(creationDate, 'dd MMMM y');

      if (value.arguments && value.arguments.length) {
        this._arguments = checkArguments(value.arguments, 3);
        this._argumentsMobile = checkArguments(value.arguments, 1);
      }
    }
  }

  trackByTagId(index: any, item: any) {
    return item.tagId;
  }

  trackByCourseCategoryId(index: any, item: any) {
    return item.courseCategoryId;
  }

  private setJobInformationCard(value: JobInformation) {
    // Check if is a JobInformation type
    if (!instanceOfJobInformation(value)) {
      this.showLogError();
    } else {
      this._title = value.title;
      this._hasApplied = value.userHasApplied;
      this._location = value.secondaryLocations.length > 1 ? value.secondaryLocations.join(', ') : value.primaryLocation;
      this._company = value.companyName ? value.companyName : value.company;
      if (value.categories && value.categories.length) {
        this._arguments = checkArguments(value.categories, 3);
        this._argumentsMobile = checkArguments(value.categories, 1);
      }
    }
  }

  private setChallengeCard(value: ChallengeCardInterface) {
    // Check if is a ChallengeCardInterface type
    if (!instanceOfChallenge(value)) {
      this.showLogError();
    } else {
      this._title = value.title;
      this._coverImage =
        value.itemImage || this.defaultImage || 'assets/img/pl.png';
      this._showBadge = value.badgeType !== undefined;
      this._badgeTitle = value.badgeTitle;
      this._badgeType = value.badgeType;

      // Timing
      this._minutes = value.durationInMinutes;
      this._hasDuration = this._minutes && this._minutes > 0;

      if (value.arguments && value.arguments.length) {
        this._arguments = checkArguments(value.arguments, 3);
        this._argumentsMobile = checkArguments(value.arguments, 1);
      }
    }
  }

  private setCm2Course(value: any) {
    this._title = value.title;
    this._arguments = value.arguments;
    this._coverImage = value.coverImage || value.cardCover;
    this._when = value.courseDuration;
    this._courseType = value.courseType;
    this._userStatus = value.userStatus;
    this._percentage = value.completionPercentage;
    this._iconSrc = value.iconSrc;
    this._where = value.where;
  }

  private setLibraryItem(value: any) {
    this._title = value.title;
    this._arguments = value.arguments;
    this._coverImage = value.coverImage || value.cardCover;
    this._when = value.courseDuration;
    this._courseType = value.courseType;
    this._userStatus = value.userStatus;
    this._iconSrc = value.iconSrc;
    this._percentage = value.completionPercentage;
    this._where = value.where;
  }

  private setCourseCard(value: CarouselCourseItem, isEvent?: boolean) {
    // Check if is a TalentFarmClasses.CarouselCourseItem type
    if (!instanceOfCarouselCourseItem(value)) {
      this.showLogError();
    } else {
      this._title = value.title;
      // Assegno la durata del corso in minuti
      this._minutes = value.durationInMinutes;
      this._hasDuration = this._minutes && this._minutes > 0;

      if (value.editions) {
        this._isEnrolled = value.editions?.some(edition => edition.userStatus && edition.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_CONFIRMED);
        this._isCompleted = value.editions?.some(edition => edition.userStatus && edition.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_PRESENT);
      }
      else {
        this._isEnrolled =
          value.userStatus &&
          value.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_CONFIRMED;
        this._isCompleted =
          value.userStatus &&
          value.userStatus === ItemTakerEnrollStatusTypes.USER_STATUS_PRESENT;
      }
      this._coverImage = value.itemImage || this.defaultImage || 'assets/img/pl.png';
      const argumentsLength = (value.arguments || []).reduce((acc, el) => acc + el.title.length, 0);
      if (value.arguments && value.arguments.length) {
        this._arguments = checkArguments(value.arguments, argumentsLength > 25 ? (value.arguments, argumentsLength > 29 ? 1 : 2) : 3);
        this._argumentsMobile = checkArguments(value.arguments, 1);
      }

      if (isEvent && value.editions?.length) {
        if (isEvent) {
          if (value.editions?.length) {
            const now: moment.Moment = moment.utc();
            // Mostro la prima edizione non futura, o in alternativa l'ultima sapendo che sono ordinate per startDate
            const firstFutureEdition = value.editions.find(edition => edition.endDate && moment.utc(edition.endDate).isAfter(now)) || value.editions[value.editions.length - 1];
            this._timing = this.datePipe.transform(firstFutureEdition?.startDate, "EEE dd MMMM ore HH:mm/");
            this._timing += this.datePipe.transform(firstFutureEdition?.endDate, "HH:mm");

            this._location = firstFutureEdition.location;

            if (value.editions.length > 1) {
              this._eventEnded = value.editions.every(edition => moment.utc(edition.endDate).isBefore(moment.utc()))
              this._multipleEditionsEventData = {
                otherEditionsCount: value.editions.length - 1,
                otherLocationsCount: new Set(value.editions.map(ed => ed.location)).size - 1
              }

            }
            else {
              this._multipleEditionsEventData = null;

              this._eventEnded = moment.utc(firstFutureEdition.endDate).isBefore(moment.utc())
            }

          }
          else {
            if (value.startDate) {
              const startDate = new Date(value.startDate);
              this._timing = this.datePipe.transform(
                startDate,
                'dd MMMM ore HH:mm/'
              );
            }

            if (value.endDate) {
              const endDate = new Date(value.endDate);
              if (!this._timing) {
                this._timing = '';
              }
              this._timing += this.datePipe.transform(endDate, 'HH:mm');
            }

          }
        } else {
          // Percentage
          if (this._hasDuration) {
            this._percentage = value.currentPrecentage;
          }
        }
      }
    }
  }

  private setCategoryCard(value: CategoryCardInterface) {
    // Check if is a CategoryCardInterface type
    if (!instanceOfCategory(value)) {
      this.showLogError();
    } else {
      this._title = value.title;
      this._coverImage =
        value.itemImage || this.defaultImage || 'assets/img/pl.png';
    }
  }

  /**
   * On enrollment button click
   * @param e Click event
   * @param tagClick Tag clicked
   * @param card Card clicked
   */
  onTagClickEvent(e, tagClick: TagInfo, card?) {
    if (this.onTagClick) {
      e.stopPropagation();
      if (!this.isCarouselOfPrems) {
        this.onTagClick.emit(tagClick);
      } else {
        let objectToEmit = {
          tag: tagClick,
          card: card,
        };
        this.onTagClick.emit(objectToEmit);
      }
    }
  }

  upperCaseFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  // Gestisce il click sull'icona del cuore della collezione
  emitToggleBookmark(event) {
    event.stopPropagation();
    this.onToggleBookmark.emit(this._card);
  }
}
