import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ContentChild, Inject, Input, OnInit, TemplateRef } from '@angular/core';

import { BlogPost } from "atfcore-commonclasses/bin/classes/blog";
import { CarouselCourseItem } from "atfcore-commonclasses/bin/classes/talent-farm";
import { OnDemandItem } from "atfcore-commonclasses/bin/classes/onDemandItem";
import { WebinarSession, JobInformation, EventOrWebinarSession } from "atfcore-commonclasses/bin/classes/coursemanager";

import { CardType } from '../card/card.component';
import { isSameDate, LibraryStyle, LIBRARY_STYLE } from '../utils';
import { ChallengeCardInterface } from '../interfaces';

export interface HeroContent {
  live?: EventOrWebinarSession,
  onDemand?: OnDemandItem,
  blog?: BlogPost,
  event?: CarouselCourseItem,
  course?: CarouselCourseItem,
  challenge?: ChallengeCardInterface,
  prize?: CarouselCourseItem,
  job?: JobInformation,
  blogHeroSubTitle?: string,
  jobHeroSubTitle?: string,
  eventHeroSubTitle?: string,
  courseHeroSubTitle?: string,
  blogHeroTitle?: string,
  jobHeroTitle?: string,
  eventHeroTitle?: string,
  courseHeroTitle?: string
  challengeHeroTitle?: string;
  challengeHeroSubTitle?: string;
  prizeHeroTitle?: string;
  prizeHeroSubTitle?: string;
};

interface HeroTab {
  type: CardType,
  title?: string,
  description?: string,
  coverImage?: string,
  noCover?: boolean,
  isToday?: boolean,
  startDate?: Date
};

@Component({
  selector: 'generali-hero',
  templateUrl: './hero.component.html',
  styleUrls: ['./hero.component.scss']
})
export class GeneraliHeroComponent implements OnInit {

  _libraryStyle: LibraryStyle;

  @Input() sectionId: string;
  @Input() arrayImages: any;
  @Input() hasCustomTabs: boolean = false;

  _isLoading: boolean = false;
  @Input() set isLoading(value: boolean | string) {
    this._isLoading = coerceBooleanProperty(value);
  };

  _buttonDisabled: boolean = false;
  @Input() set buttonDisabled(value: boolean | string) {
    this._buttonDisabled = coerceBooleanProperty(value);
  };

  _heroContent: HeroContent | undefined;
  @Input() set heroContent(value: any) {
    this._heroContent = value;
    this.getHeroContent();
  };

  _customTabsContent;
  @Input() set customTabsContent(value: any) {
    this._customTabsContent = value;
    this.getHeroCustomTabsContent();
  };

  @Input() noCoverAll: boolean;

  /**
   * Time expressed in seconds for autoscroll
   */
  _autoScrollTime: number = 10;
  @Input() set autoScrollTime(value: number) {
    this._autoScrollTime = value ? value : 10;
  };

  @ContentChild('onEnergyLabButton', { static: false }) onEnergyLabButton: TemplateRef<any>;
  @ContentChild('liveButtons', { static: false }) liveButtons: TemplateRef<any>;
  @ContentChild('onDemandButtons', { static: false }) onDemandButtons: TemplateRef<any>;
  @ContentChild('blogButtons', { static: false }) blogButtons: TemplateRef<any>;
  @ContentChild('eventButtons', { static: false }) eventButtons: TemplateRef<any>;
  @ContentChild('courseButtons', { static: false }) courseButtons: TemplateRef<any>;
  @ContentChild('challengeButtons', { static: false }) challengeButtons: TemplateRef<any>;
  @ContentChild('jobButtons', { static: false }) jobButtons: TemplateRef<any>;
  @ContentChild('prizeButtons', { static: false }) prizeButtons: TemplateRef<any>;
  @ContentChild('customButtons', { static: false }) customButtons: TemplateRef<any>;

  _titleTabs: string[] = [];
  _heroTabs: any[] = [];

  _isUserEnrolled: boolean = false;

  get isSmartEnergy(): boolean { return this._libraryStyle === 'SMART_ENERGY' };

  constructor(@Inject(LIBRARY_STYLE) libraryStyle: LibraryStyle) {
    this._libraryStyle = libraryStyle;
  }

  ngOnInit() {
    if (!this.sectionId) {
      this.sectionId = 'hero-' + Math.floor((Math.random() * 10000) + 1);
    }
  }

  trackBy(index: any, item: any) {
    return index;
  }

  /**
   * Set the hero content
   */
  getHeroContent() {

    this._titleTabs = [];
    this._heroTabs = [];

    if (Array.isArray(this._heroContent)) {
      // Set the on demand hero page
      let onDemandArray = this._heroContent;
      for (let i = 0; i < onDemandArray.length; i++) {
        this._heroTabs.push({
          type: 'on-demand-event',
          title: onDemandArray[i].title,
          coverImage: onDemandArray[i].itemImage,
          containerId: onDemandArray[i].containerId,
          collectionId: onDemandArray[i].collectionId,
          itemId: onDemandArray[i].itemId,
          savedForLater: onDemandArray[i].savedForLater
        });
        this._titleTabs.push('hero.ON_DEMAND');
      }
      if (this.isSmartEnergy) {
        this._heroTabs.unshift(
          {
            isEnergyLabLink: true,
            coverImage: 'assets/img/hero/smart_energy_new_image.png'
          }
        )
        this._titleTabs.unshift('hero.CUSTOM_LINK_ENERGY_HUB');
      }
    } else if (this._heroContent) {

      if (this.isSmartEnergy) {
        // SMART ENERGY

        // Set the blog hero page
        const blog = this._heroContent.blog;
        if (blog) {
          this._heroTabs.push({
            type: 'blog',
            title: blog.title,
            coverImage: blog.postImage,
            description: blog.subTitle
          });
          this._titleTabs.push('hero.BLOG');
        }

        // Set the live hero page
        /* const live = this._heroContent.live;
        if (live) {
          this._heroTabs.push({
            type: 'live-event',
            title: live.title,
            coverImage: live.coverImage,
            isToday: isSameDate(new Date(live.startDate), new Date()),
            startDate: new Date(live.startDate)
          });
          //this._isUserEnrolled = live.isUserEnrolled;
          this._titleTabs.push('hero.LIVE');
        } */

        // Set the on demand hero page
        const onDemand = this._heroContent.onDemand;
        if (onDemand) {
          this._heroTabs.push({
            type: 'on-demand-event',
            title: onDemand.title,
            coverImage: onDemand.itemImage
          });
          this._titleTabs.push('hero.ON_DEMAND');
        }

        this._heroTabs.unshift(
          {
            isEnergyLabLink: true,
            coverImage: 'assets/img/hero/smart_energy_new_image.png'
          }
        )
        this._titleTabs.unshift('hero.CUSTOM_LINK_ENERGY_HUB');
      } else {
        // GO GENERALI

        // Set the news hero page
        const blog = this._heroContent.blog;
        if (blog) {
          if (this.noCoverAll) {
            this._heroTabs.push({
              type: 'blog',
              title: this._heroContent.blogHeroTitle || '',
              noCover: true,
              description: this._heroContent.blogHeroSubTitle || ''
            });
          } else {
            this._heroTabs.push({
              type: 'blog',
              title: blog.title,
              coverImage: blog.postImage,
              description: blog.subTitle
            });
          }
        } else {
          this._heroTabs.push({
            type: 'blog',
            title: 'hero.news.TITLE',
            description: 'hero.news.DESCRIPTION',
            noCover: true
          });
        }

        this._titleTabs.push('hero.NEWS');

        // Set the jobs hero page+
        //TODO-ATS (27-07-2023)
        // const job = this._heroContent.job;
        // if (job) {
        //   if (this.noCoverAll) {
        //     this._heroTabs.push({
        //       type: 'job',
        //       title: this._heroContent.jobHeroTitle || '',
        //       noCover: true,
        //       description: this._heroContent.jobHeroSubTitle || ''
        //     });
        //   } else {
        //     this._heroTabs.push({
        //       type: 'job',
        //       title: job.title,
        //       coverImage: job.itemImage,
        //       description: job.descriptionInternalHTML
        //     });
        //   }
        // } else {
        //   this._heroTabs.push({
        //     type: 'job',
        //     title: 'hero.jobs.TITLE',
        //     description: 'hero.jobs.DESCRIPTION',
        //     noCover: true
        //   });
        // }
        //this._titleTabs.push('hero.JOBS');

        // Set the course hero page
        const course = this._heroContent.course;
        if (course) {
          if (this.noCoverAll) {
            this._heroTabs.push({
              type: 'course',
              title: this._heroContent.courseHeroTitle || '',
              description: this._heroContent.courseHeroSubTitle || '',
              noCover: true
            });
          } else {
            this._heroTabs.push({
              type: 'course',
              title: course.title,
              description: course.description,
              coverImage: course.itemImage
            });
          }
        } else {
          this._heroTabs.push({
            type: 'course',
            title: 'hero.courses.TITLE',
            description: 'hero.courses.DESCRIPTION',
            noCover: true
          });
        }
        this._titleTabs.push('hero.COURSES');

        // Set the events hero page
        const event = this._heroContent.event;
        if (event) {
          if (this.noCoverAll) {
            this._heroTabs.push({
              type: 'general-event',
              title: this._heroContent.eventHeroTitle || '',
              description: this._heroContent.eventHeroSubTitle || '',
              noCover: true
            });
          } else {
            this._heroTabs.push({
              type: 'general-event',
              title: event.title,
              description: event.description,
              coverImage: event.itemImage
            });
          }
        } else {
          this._heroTabs.push({
            type: 'general-event',
            title: 'hero.events.TITLE',
            description: 'hero.events.DESCRIPTION',
            noCover: true
          });
        }
        this._titleTabs.push('hero.EVENTS');

        // Set the challenges hero page
        const challenge = this._heroContent.challenge;
        if (challenge) {
          if (this.noCoverAll) {
            this._heroTabs.push({
              type: 'challenge',
              title: this._heroContent.challengeHeroTitle || '',
              noCover: true,
              description: this._heroContent.challengeHeroSubTitle || ''
            });
          } else {
            this._heroTabs.push({
              type: 'challenge',
              title: challenge.title,
              description: challenge.description,
              coverImage: challenge.itemImage
            });
          }
        } else {
          this._heroTabs.push({
            type: 'challenge',
            title: 'hero.challenges.TITLE',
            description: 'hero.challenges.DESCRIPTION',
            noCover: true
          });
        }
        this._titleTabs.push('hero.CHALLENGES');

        // Set the prizes hero page
        const prize = this._heroContent.prize;
        if (prize) {
          if (this.noCoverAll) {
            this._heroTabs.push({
              type: 'premium',
              title: this._heroContent.prizeHeroTitle || '',
              noCover: true,
              description: this._heroContent.prizeHeroSubTitle || ''
            });
          } else {
            this._heroTabs.push({
              type: 'premium',
              title: prize.title,
              description: prize.description,
              coverImage: prize.itemImage
            });
          }
        } else {
          this._heroTabs.push({
            type: 'premium',
            title: 'hero.prizes.TITLE',
            description: 'hero.prizes.DESCRIPTION',
            noCover: true
          });
        }
        /* this._titleTabs.push('hero.PRIZES'); */
      }
    }
  }

  trackBySlideId(index: any, item: any) {
    return item.slideId;
  }

  /**
   * Imposta il contenuto dell'hero quando si tratta di tab custom (quindi non fisse)
   */
  getHeroCustomTabsContent() {
    this._titleTabs = [];
    this._heroTabs = [];

    if (this.hasCustomTabs && this._customTabsContent && this._customTabsContent.length) {
      for (let m = 0, tabsLength = this._customTabsContent.length; m < tabsLength; m++) {
        const currentTab = this._customTabsContent[m];
        this._heroTabs.push(currentTab);
        this._titleTabs.push(currentTab.title + '-' + m);
      }
    }
  }
}
