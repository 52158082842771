import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  Injector,
  Input,
  OnDestroy
} from "@angular/core";
import { GeneraliTooltipComponent } from "./tooltip.component";

@Directive({
  selector: '[tooltip]'
})
export class GeneraliTooltipDirective implements OnDestroy {
  constructor(private elementRef: ElementRef, private appRef: ApplicationRef, private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector) { }

  @Input() tooltip = "";
  @Input() showTooltip: boolean = true;

  private componentRef: ComponentRef<any> = null;
  private top: number;
  private left: number;
  private time: ReturnType<typeof setTimeout>;

  @HostListener('mouseenter', ['$event'])
  private delay() {
    if (this.showTooltip === undefined || this.tooltip) {
      this.time = setTimeout(() => this.onMouseEnter(), 700)
    }
  }

  @HostListener('mousemove', ['$event'])
  private onMouseMove($event: MouseEvent): any {
    this.left = $event.clientX;
    this.top = $event.clientY;
  }

  private onMouseEnter(): void {
    if (this.componentRef === null) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(GeneraliTooltipComponent);
      this.componentRef = componentFactory.create(this.injector);
      this.appRef.attachView(this.componentRef.hostView);
      const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;

      document.body.appendChild(domElem);
      this.setTooltipComponentProperties();
    }
  }

  private setTooltipComponentProperties() {
    const topOffset = this.top + 15
    const { left } = this.elementRef.nativeElement.getBoundingClientRect();
    if (this.componentRef !== null) {
      if (this.tooltip.length > 25 && this.left + 200 > window.innerWidth) {
        this.componentRef.instance.left = left;
      } else {
        this.componentRef.instance.left = this.left;
      }
      const a = this.tooltip.length / 25
      if (this.top + a * 10 + 50 > window.innerHeight) {
        this.componentRef.instance.top = topOffset - 50 - (a * 10);
      } else {
        this.componentRef.instance.top = topOffset;
      }

      this.componentRef.instance.tooltip = this.tooltip;
    }
  }

  @HostListener('mouseleave')
  private onMouseLeave(): void {
    clearTimeout(this.time)
    this.destroy()
  }

  @HostListener('click', ['$event.target'])
  private onClickEvt() {
    clearTimeout(this.time)
    this.destroy();

  }

  private destroy(): void {
    if (this.componentRef !== null) {
      const domElem = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
      document.body.removeChild(domElem)

      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();

      this.componentRef = null;
    }
  }

  ngOnDestroy(): void {
    this.destroy();
  }
}