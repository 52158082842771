import { Injectable, ModuleWithProviders, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

// Utils
import { GeneraliModuleConfig, LIBRARY_STYLE } from './utils';

// Componenti
import * as Hammer from 'hammerjs';
import { GeneraliBadgeComponent } from './badge/badge.component';
import { GeneraliCardComponent } from './card/card.component';
import { GeneraliCardCarouselComponent } from './card-carousel/card-carousel.component';
import { GeneraliCarouselComponent } from './carousel/carousel.component';
import { GeneraliHeroComponent } from './hero/hero.component';
import { GeneraliHeroBlogComponent } from './hero-blog/hero-blog.component';
import { GeneraliLoaderComponent } from './loader/loader.component';
import { GeneraliProfilingComponent } from './profiling/profiling.component';
import { GeneraliHomeBlogComponent } from './home-blog/home-blog.component';
import { libraryTranslateModule } from './translate-module';
import { GeneraliTooltipComponent } from './tooltip/tooltip.component';
import { HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { GeneraliTooltipDirective } from './tooltip/tooltip.directive';

// import * as t from "angular-tooltips"

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_HORIZONTAL },
    pinch: { enable: false },
    rotate: { enable: false },
    pan: { enable: true }
  };
}


@NgModule({
  declarations: [
    GeneraliTooltipDirective,
    GeneraliCarouselComponent,
    GeneraliCardComponent,
    GeneraliCardCarouselComponent,
    GeneraliBadgeComponent,
    GeneraliLoaderComponent,
    GeneraliHeroComponent,
    GeneraliHeroBlogComponent,
    GeneraliProfilingComponent,
    GeneraliHomeBlogComponent,
    GeneraliTooltipComponent,
  ],
  imports: [
    CommonModule,
    HttpClientModule,
    AngularSvgIconModule,
    FlexLayoutModule,
    libraryTranslateModule,
    HammerModule,
  ],
  exports: [
    GeneraliTooltipDirective,
    GeneraliCarouselComponent,
    GeneraliCardCarouselComponent,
    GeneraliBadgeComponent,
    GeneraliCardComponent,
    GeneraliLoaderComponent,
    GeneraliHeroComponent,
    GeneraliHeroBlogComponent,
    GeneraliProfilingComponent,
    GeneraliHomeBlogComponent,
    GeneraliTooltipComponent
  ]
})
export class GeneraliModule {
  /**
   * Use this method in your root module to provide the style
   */
  static forRoot(config: GeneraliModuleConfig = { libraryStyle: 'SMART_ENERGY' }): ModuleWithProviders<GeneraliModule> {
    return {
      ngModule: GeneraliModule,
      providers: [
        { provide: LIBRARY_STYLE, useValue: config.libraryStyle },
        {
          provide: HAMMER_GESTURE_CONFIG,
          useClass: MyHammerConfig
        }
      ]
    };
  }
}
