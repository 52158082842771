export const en = {
  card: {
    YOU_ARE_NOT_SUBSCRIBED: "You are not subscribed",
    SUBSCRIBED_ONLINE_LIVE: "online registration completed",
    SUBSCRIBED_ONLINE_PRESENCE: "presence registration carried out",
    SUBSCRIBED_ONLINE_LIVE_PRESENCE: "online and presence registration",
    SUBSCRIBED_ONLINE_LIVE_PRESENCE_MOBILE: "online and presence subscription",
    NOT_SUBSCRIBED_EVENT: "Not subscribed",
    VIEW_ALL_GO_LIVE_EVENT: "Go to calendar",
    ARTICLE: "Article",
    VIDEO: "Video",
    PODCAST: "Podcast",
    VIEW_ALL: "View all ({{value}})",
    VIEW_ALL_GO: "View all ({{value}})",
    SUBSCRIBED: "registration completed",
    PARTICIPATED: "partecipation completed",
    NOT_SUBSCRIBED: "you are not registered yet",
    NOT_SUBSCRIBED_F: "you are not registered yet",
    NOT_ENROLLED: "You are not registered yet",
    NOT_ENROLLED_F: "You are not registered yet",
    TO_COMPLETE: "To complete",
    TO_START: "To start",
    NOT_PARTICIPATED: "participate",
    NOT_PARTICIPATED_F: "participate",
    REGISTRATIONS_CLOSED: "registrations closed",
    PARTICIPATIONS_CLOSED: "partecipations closed",
    STARTED_FROM: "Started from 1 minute",
    STARTED_FROMS: "Started from {{value}} minutes",
    DURATION: "Duration 1 minute",
    DURATIONS: "Duration {{value}} minutes",
    COMPLETED: "Completed",
    PRIZE_TO_REDEEM: "Prize to redeem",
    PRIZE_REDEEMED: "Prize redeemed",
    NOW_NOT_AVAILABLE: "Not available at the moment",
    NOW_DISPONIBLE: "1 still available",
    NOW_DISPONIBLES: " still availables",
    OTHER_EDITIONS: " other editions",
    OTHER_LOCATION: " other place",
    OTHER_LOCATIONS: " other places",
    MULTIPLE_EDITIONS_EVENT: "Event with multiple editions",
    EVENT_ENDED: "Event ended"
  },
  hero: {
    CUSTOM_LINK_ENERGY_HUB: "Link Energy Hub",
    LIVE_SMART_ENERGY_EXPERIENCE: "Live experience Smart Energy",
    BLOG: "BLOG",
    LIVE: "LIVE",
    PRIZES: "PRIZES",
    ON_DEMAND: "ON DEMAND",
    NEWS: "INSIGHT",
    EVENTS: "EVENTS",
    COURSES: "COURSES",
    CHALLENGES: "CHALLENGES",
    JOBS: "JOBS",
    LIVE_ON: "Live on {{ value }}",
    LIVE_TODAY: "Live today",
    AT: "at {{ value }}",
    ENROLL: "Subscribe",
    CANCEL_ENROLL: "Unsubscribe",
    ON_DEMAND_CONTENT: "On demand content",
    BLOG_CONTENT: "Blog content",
    LEARN_MORE: "Learn more",
    LOOK: "Look now",
    SAVE: "Save",
    REMOVE_FROM_SAVE: "Remove",
    news: {
      TITLE: "Insight",
      DESCRIPTION: "Explore the blog of Go Generali"
    },
    jobs: {
      TITLE: "Position",
      DESCRIPTION: "Check out the latest open positions in Generali and send your application"
    },
    prizes: {
      TITLE: "Prizes",
      DESCRIPTION: "Search and consult the prizes"
    },
    courses: {
      TITLE: "Training",
      DESCRIPTION: "Discover the courses of your interest and<br> learn new skills"
    },
    events: {
      TITLE: "Events",
      DESCRIPTION: "Attend the events of your interest, online and live"
    },
    challenges: {
      TITLE: "Challenges",
      DESCRIPTION: "Complete the challenges to win coins and to take advantage of the Premium content"
    },
    DISCOVER_ENERGY_LAB: "Discover Energy Hub",
    SUBSCRIBE_TO_NEW_COURSES: "Subscribe to new courses",
    ENERGY_HUB: "Energy Hub",
    ENERGY_HUB_SCADENCE_COURSES_DESC: "Courses end Wednesday 20.12. They will resume on Wednesday 10.01"
  }
};
