<generali-carousel [isFullWindow]="true" [manageSwipe]="true" [preventOverflowMarginOnRight]="true" hasDots
  noPlaceholder [sectionId]="sectionId" [contentLength]="_articles.length" [isLoading]="_isLoading" [index]="0"
  [scrollToLeft]="_scrollToLeft.asObservable()" [scrollToRight]="_scrollToRight.asObservable()"
  (onScrollEvent)="onScrollEvent($event)" [goToStartOnFinish]="true" [fromHero]="true">
  <!-- Loader -->
  <ng-container *ngIf="_isLoading">
    <div class="hero-blog">
      <generali-loader></generali-loader>
    </div>
  </ng-container>
  <!-- Contenuto carosello -->
  <ng-container *ngIf="!_isLoading">
    <div class="hero-blog"
      *ngFor="let article of _articles; let index = index; let first = first; let last = last; trackBy: trackByItemId"
      fxLayout="row">

      <div class="hero-blog-content" [ngClass]="_libraryStyle" fxLayout="column" fxLayoutAlign="start start"
        [ngStyle]="{'background-image': article.postImage ? ('url(' + article.postImage + ')') : '' }">

        <!-- Left panel -->
        <div *ngIf="!first && isSmartEnergy" class="side-space-blog-pannel left flex-hide-xs" fxLayout="row"
          fxLayoutAlign="center center">
          <svg-icon src="assets/icons/icon-left-{{isSmartEnergy ? 'smart' : 'go'}}.svg" [applyClass]="true"
            (click)="scrollLeft()">
          </svg-icon>
        </div>

        <!-- Left panel -->
        <div *ngIf="!first && !isSmartEnergy" class="side-space-blog-pannel left flex-hide-xs" fxLayout="row"
          fxLayoutAlign="center center">
          <div (click)="scrollLeft()" fxLayout="column" fxLayoutAlign="center center" class="side-space-blog-pannel-circle">
          <svg-icon src="assets/icons/icon-arrow-left-{{isSmartEnergy ? 'smart' : 'go'}}.svg" [applyClass]="true">
          </svg-icon>
        </div>
        </div>

        <!-- Right panel -->
        <div *ngIf="!last && isSmartEnergy" class="side-space-blog-pannel right flex-hide-xs" fxLayout="row"
          fxLayoutAlign="center center">
          <svg-icon src="assets/icons/icon-right-{{isSmartEnergy ? 'smart' : 'go'}}.svg" [applyClass]="true"
            (click)="scrollRight()">
          </svg-icon>
        </div>

        <!-- Right panel -->
        <div *ngIf="!isSmartEnergy" class="side-space-blog-pannel right flex-hide-xs" fxLayout="row"
          fxLayoutAlign="center center">
          <div (click)="scrollRight()" fxLayout="column" fxLayoutAlign="center center" class="side-space-blog-pannel-circle">
          <svg-icon src="assets/icons/icon-arrow-right-{{isSmartEnergy ? 'smart' : 'go'}}.svg" [applyClass]="true">
          </svg-icon>
        </div>
        </div>

        <h5 fxFlexOffset="68px" fxFlexOffset.xs="100px" tabindex="-1">
          {{ (article.arguments && article.arguments.length) ? article.arguments[0].title : '' }}
        </h5>

        <div *ngIf="article.title" [ngClass]="{'in-smart-energy': isSmartEnergy}" class="hero-blog-text" [fxFlexOffset]="isSmartEnergy ? '12px' : '4px'">
          <h1 class="flex-show-gt-md" tabindex="-1">
            {{ (article.title.length>94)? (article.title | slice:0:94)+'...':(article.title) }}
          </h1>
          <h1 fxHide fxShow.md fxShow.sm tabindex="-1">
            {{ (article.title.length>48)? (article.title | slice:0:48)+'...':(article.title) }}
          </h1>
          <h1 class="flex-show-xs" tabindex="-1">
            {{ (article.title.length>32)? (article.title | slice:0:32)+'...':(article.title) }}
          </h1>
        </div>

        <div class="hero-button-container" [fxFlexOffset]="isSmartEnergy ? '15px' : '32px'"
          [fxFlexOffset.xs]="isSmartEnergy ? '24px' : '34px'" fxLayout="row">

          <!-- Show All button -->
          <ng-container *ngIf="showAllButton" [ngTemplateOutlet]="showAllButton"
            [ngTemplateOutletContext]="{$implicit:index}">
          </ng-container>

        </div>

      </div>
    </div>
  </ng-container>

</generali-carousel>