import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'generali-tooltip',
  template: `
    <div class="tooltip" [style.left]="left + 'px'" [style.top]="top + 'px'">{{ tooltip }}</div>
  `,
  styles: [
    `
      .tooltip {
        position: fixed;
        z-index: 99999;

        background-color: black;
        border-radius: 4px;

        font-family: Arial;
        font-size: 13px;
        color: #ffffff;
        pointer-events: none;
        
        padding: 3px 6px;
        margin-top: 5px;

        max-inline-size: 150px;
        overflow-wrap: break-word;
      }
    `
  ]
})
export class GeneraliTooltipComponent implements OnInit {
  constructor() { }

  public visible = false
  tooltip: string = '';
  left: number = 0;
  top: number = 0;

  ngOnInit(): void { }

}
