<div class="wrapper-top-ten-number"
  *ngIf="!isCm2Course() && !isLibraryItem() && !isWebCollection() && !isItemOfWebCollection() && !isPlaylistItem()">
  <p class="top-ten-number" [ngClass]="{ 'ten' : cardNumber && cardNumber == 10 }"
    *ngIf="(isTopLiveEvent() || isTopTenCourses()) && !isShowAll">{{ cardNumber || 1 }}</p>

  <div (click)="onCardClick.emit(_card)" [ngClass]="{'pointer-none': isVetrin}" [className]="'card ' +
    (!isShowAll ?  'align-space-between' : 'align-center-center') + ' ' +
    (_libraryStyle ? _libraryStyle : '' ) + ' ' +
    (isJob() ? 'job' : '') +  ' ' +
    (isArticle() ? 'blog' : '') +  ' ' +
    (isCategory() ? 'category' : '') +  ' ' +
    (isTopLiveEvent() ? 'top-ten' : '') + ' ' +
    (isTopTenCourses() ? 'top-ten-courses' : '') + ' ' +
    (withPremiumMobileWidth ? 'with-premium-mobile-width' : '') + ' ' +
    (isPastEvent() && distinctForPastEvents? 'past-event': '') + ' '
  ">

    <div *ngIf="isShowAll" fxLayout="row" fxLayoutAlign="center center" class="cursor-pointer view-all">
      <h6 *ngIf="isSmartEnergy()">{{ viewAllLabel || ('card.VIEW_ALL' | translate: { value: viewAllNumber } )}}</h6>
      <h6 *ngIf="!isSmartEnergy()">{{ viewAllLabel || ('card.VIEW_ALL_GO' | translate: { value: viewAllNumber } |
        uppercase
        ) }}</h6>
      <svg-icon [src]="isSmartEnergy() ? 'assets/icons/icon-view-all-smart.svg' : 'assets/icons/icon-view-all-go.svg'">
      </svg-icon>
    </div>

    <div *ngIf="!isJob() && !isCategory() && !isShowAll" class="card-image"
      [ngStyle]="{ 'background-image': 'url(' + _coverImage + ')' }">
    </div>

    <generali-badge [isVetrin]="isVetrin" class="card-badge" *ngIf="_showBadge && !isShowAll" [title]="_badgeTitle"
      [type]="_badgeType">
    </generali-badge>

    <generali-badge [isVetrin]="isVetrin" class="card-badge"
      *ngIf="_card && _card.completionPremiumCoinsPrize && !isShowAll"
      title="OTTIENI {{_card.completionPremiumCoinsPrize}}" type="primary"></generali-badge>

    <generali-badge [isVetrin]="isVetrin" class="card-badge"
      *ngIf="_card && _card.requiredPremiumCoins && !_card.completionPremiumCoinsPrize && !isShowAll"
      [title]="'PREMIUM'" [type]="'premium-badge'">
    </generali-badge>

    <!-- Percentuale di avanzamento -->
    <div *ngIf="_percentage && _percentage > 0 && !isShowAll" class="percentage flex-hide-xs">
      <div class="percentage-bar" [ngStyle]="{'width': (_percentage + '%')}"></div>
    </div>

    <div *ngIf="!isShowAll" class="card-content" fxLayout="column" fxLayoutAlign="space-between stretch"
      [ngStyle]="{ 'background-image': (isCategory() && _coverImage) ? 'url(' + _coverImage + ')' : '' }">

      <!-- Titolo card -->
      <div [showTooltip]="showTooltip" [tooltip]="_title" fxLayout="column" fxLayoutGap="8px">
        <!-- Evento live -->
        <div class="live" fxLayout="column" fxLayoutGap="8px" tabindex="-1" *ngIf="!isCarouselOfPrems &&
            (hasSubscription() ||
            isOnDemandEvent() ||
            isCourse() ||
            isGeneralEvent() ||
            isArticle() ||
            isPrem())">
          <!-- Live adesso / Evento live -->
          <h5 *ngIf="hasLive() && !isSmartEnergy()">
            {{ _isInliveNow ? '&#9679; LIVE ADESSO' : 'EVENTO LIVE' }}
          </h5>
          <h5 *ngIf="isLiveEvent() && isSmartEnergy() && _card.webinarSession && !_card.eventSession">
            {{ _isInliveNow ? '&#9679; LIVE ADESSO' : 'EVENTO LIVE' }}
          </h5>
          <h5 *ngIf="isTopLiveEvent() && isSmartEnergy()">
            {{ 'EVENTO IN DIRETTA' }}
          </h5>
          <h5 *ngIf="isLiveEvent() && isSmartEnergy() && !_card.webinarSession && _card.eventSession">
            {{ 'IN PRESENZA' }}
          </h5>

          <h5 fxShow fxHide.xs *ngIf="isLiveEvent() && isSmartEnergy() && _card.webinarSession && _card.eventSession">
            {{ 'IN PRESENZA E IN LIVE STREAMING' }}
          </h5>

          <h5 fxShow.xs fxHide *ngIf="isLiveEvent() && isSmartEnergy() && _card.webinarSession && _card.eventSession">
            {{ 'IN PRESENZA E IN LIVE' }}
          </h5>
          <!-- Evento concluso -->
          <h5 *ngIf="_eventEnded" class="bold">
            <span>
              {{'card.EVENT_ENDED' | translate | uppercase}}
            </span>
          </h5>
          <!-- Evento multi edizione non concluso -->
          <h5 *ngIf="!_eventEnded && _multipleEditionsEventData" class="bold">
            <span>
              {{'card.MULTIPLE_EDITIONS_EVENT' | translate | uppercase}}
            </span>
          </h5>

          <h6>
            <!-- Iniziato da x minuti -->
            <ng-container *ngIf="_isInliveNow">
              <ng-container *ngIf="_minutes === 1">
                {{ 'card.STARTED_FROM' | translate }}
              </ng-container>
              <ng-container *ngIf="_minutes !== 1">
                {{ 'card.STARTED_FROMS' | translate: { value: _minutes } }}
              </ng-container>
            </ng-container>
            <!-- Durata x minuti -->
            <ng-container *ngIf="_hasDuration">
              <ng-container *ngIf="_minutes === 1">
                {{ 'card.DURATION' | translate }}
              </ng-container>
              <ng-container *ngIf="_minutes !== 1">
                {{ 'card.DURATIONS' | translate: { value: _minutes } }}
              </ng-container>
            </ng-container>
            <!-- Data formattata per un evento non concluso -->
            <ng-container *ngIf="((!_isInliveNow && !_hasDuration) || isGeneralEvent()) && !_eventEnded">
              <span>{{ _timing }}</span>
              <!-- Se evento con più edizioni, mostro info apposita per edizioni -->
              <span *ngIf="_multipleEditionsEventData">&nbsp;{{' + ' + _multipleEditionsEventData.otherEditionsCount +
                ('card.OTHER_EDITIONS' | translate)}}</span>
            </ng-container>
          </h6>

        </div>

        <!-- Top ten course -->
        <div class="live" tabindex="-1" *ngIf="isTopTenCourses() && _card.courseType">
          <!-- Live adesso / Evento live -->
          <h5 class="top-ten-course-type">
            {{ _card.courseType }}
          </h5>
        </div>

        <p class="premium-coins-to-buy-text" *ngIf="isCarouselOfPrems && _card && _card.requiredPremiumCoins">{{_card &&
          _card.requiredPremiumCoins}} COIN</p>

        <p *ngIf="isArticle() && _card && _card.contentType" class="red-tipology-text">{{('card.' + _card.contentType) |
          translate}}</p>

        <p *ngIf="isTopTenCourses() && _card.courseDuration" class="top-courses-when only-2-rows">
          <!-- Durata x minuti / Data e ora -->
          {{ _card.courseDuration }}
        </p>

        <!-- Titolo dei corsi top ten -->
        <h5 class="only-2-rows top-ten-course-title" *ngIf="_title && !isJob() && isTopTenCourses()" tabindex="-1">
          {{ _title }}</h5>

        <h5 class="only-2-rows" *ngIf="_title && !isJob() && !isLiveEvent() && !isTopTenCourses()" tabindex="-1"
          [fxFlexOffset]="isTopLiveEvent() ? '8px' : '24px'" [fxFlexOffset.xs]="isTopLiveEvent() ? '8px' : '18px'"
          [ngClass]="{'bold': isSmartEnergy()}">
          {{ _title }}</h5>

        <h5 class="only-2-rows bold" *ngIf="_title && isLiveEvent() && !isTopTenCourses()" tabindex="-1"
          [fxFlexOffset]="'12px'" [fxFlexOffset.xs]="'8px'">
          {{ _title }}</h5>

        <h5 *ngIf="_title && isJob()" tabindex="-1" [fxFlexOffset]="isTopLiveEvent() ? '8px' : '24px'"
          [fxFlexOffset.xs]="isTopLiveEvent() ? '8px' : '18px'" [ngClass]="{'bold': isSmartEnergy()}">
          {{ (_title.length>60)? (_title | slice:0:60)+'...':(_title) }}</h5>

        <!-- <h5 class="only-2-rows" *ngIf="_title && isJob() && _arguments && _arguments.length > 1" tabindex="-1"
          [ngClass]="{'bold': isSmartEnergy()}">
          {{ _title }}</h5> -->

        <h5 class="only-2-rows" *ngIf="isOnDemandEvent()" tabindex="-1" fxFlexOffset="8px" class="card-enumeration">
          {{ _serieTitle + _enumerationSerie }}
        </h5>

        <!-- Premio da riscattare/riscattato -->
        <div *ngIf="isCarouselOfPrems" [fxFlexOffset]="'12px'" tabindex="-1" fxLayout="row"
          fxLayoutAlign="start center">
          <svg-icon *ngIf="_card && !_card.alreadyRedeemed" src="assets/icons/icon-minus-outline-new.svg"></svg-icon>
          <svg-icon *ngIf="_card && _card.alreadyRedeemed" src="assets/icons/icon-check-ok.svg"></svg-icon>
          <h6 *ngIf="_card && !_card.alreadyRedeemed" tabindex="-1" fxFlexOffset="3px">{{ 'card.PRIZE_TO_REDEEM' |
            translate }}</h6>
          <h6 *ngIf="_card && _card.alreadyRedeemed" tabindex="-1" fxFlexOffset="3px">{{ 'card.PRIZE_REDEEMED' |
            translate
            }}</h6>
        </div>

        <!-- Premio ancora disponibili o no -->
        <div *ngIf="isCarouselOfPrems && _card.itemType == 'MANUAL_PRIZE'" [fxFlexOffset]="'8px'" tabindex="-1"
          fxLayout="row" fxLayoutAlign="start center">
          <h6 *ngIf="_card && _card.currentlyAvailablePrizes && _card.currentlyAvailablePrizes == 1" tabindex="-1"
            fxFlexOffset="3px">{{ 'card.NOW_DISPONIBLE' | translate }}</h6>
          <h6 *ngIf="_card && _card.currentlyAvailablePrizes && _card.currentlyAvailablePrizes > 1" tabindex="-1"
            fxFlexOffset="3px">{{_card.currentlyAvailablePrizes}} {{ 'card.NOW_DISPONIBLES' | translate }}</h6>
          <h6 *ngIf="_card && !_card.currentlyAvailablePrizes" tabindex="-1" fxFlexOffset="3px">{{
            'card.NOW_NOT_AVAILABLE' | translate }}</h6>
        </div>

        <!-- Posizione general-event -->
        <div *ngIf="(!isCarouselOfPrems && isGeneralEvent() && _location) && !_eventEnded" class="location"
          tabindex="-1" fxFlexOffset="16px" fxLayout="row" fxLayoutAlign="start center">
          <svg-icon class="position margin-right8" src="assets/icons/icon-map-pin.svg"></svg-icon>
          <h6 tabindex="-1">{{ _location }}</h6>
          <!-- Casi in cui sia un evento con più edizioni -->
          <h6 *ngIf="_multipleEditionsEventData?.otherLocationsCount === 1">&nbsp;{{' + ' +
            _multipleEditionsEventData.otherLocationsCount + ('card.OTHER_LOCATION' | translate)}}</h6>
          <h6 *ngIf="_multipleEditionsEventData?.otherLocationsCount > 1">&nbsp;{{' + ' +
            _multipleEditionsEventData.otherLocationsCount + ('card.OTHER_LOCATIONS' | translate)}}</h6>
        </div>

        <!-- Posizione live-event -->
        <div *ngIf="!isCarouselOfPrems && isLiveEvent() && _location && isSmartEnergy()" class="location" tabindex="-1"
          fxFlexOffset="16px" fxLayout="row" fxLayoutAlign="start center">
          <svg-icon class="position margin-right8" src="assets/icons/icon-map-pin.svg"></svg-icon>
          <h6 class="only-1-row" *ngIf="_card.eventSession && !_card.webinarSession" tabindex="-1">{{ _location }}</h6>
          <h6 class="only-1-row" *ngIf="_card.eventSession && _card.webinarSession" tabindex="-1">{{ _location + ' e
            online' }}</h6>
        </div>

        <!-- Completato -->
        <div *ngIf="!isCarouselOfPrems && _isCompleted && hasSubscription() && !_eventEnded"
          [fxFlexOffset]="(isTopLiveEvent() || (isGeneralEvent() && _location)) ? '8px' : '24px'"
          [fxFlexOffset.xs]="(isTopLiveEvent() || (isGeneralEvent() && _location)) ? '8px' : '22px'" tabindex="-1"
          fxLayout="row" fxLayoutAlign="start center">
          <svg-icon src="assets/icons/icon-check-ok.svg"></svg-icon>
          <h6 tabindex="-1" fxFlexOffset="7px">{{ 'card.COMPLETED' | translate }}</h6>
        </div>

        <!-- Iscrizione effettuata -->
        <div
          *ngIf="!hasLive() && !isCarouselOfPrems && !_isCompleted && _isEnrolled && hasSubscription() && !_eventEnded"
          [fxFlexOffset]="(isTopLiveEvent() || (isGeneralEvent() && _location)) ? '8px' : '24px'"
          [fxFlexOffset.xs]="(isTopLiveEvent() || (isGeneralEvent() && _location)) ? '8px' : '22px'" tabindex="-1"
          fxLayout="row" fxLayoutAlign="start center">
          <!--<div *ngIf="!hasLive() && !isCarouselOfPrems && !_isCompleted && _isEnrolled && hasSubscription()"
        [fxFlexOffset]="'24px'" [fxFlexOffset.xs]="'22px'" tabindex="-1" fxLayout="row" fxLayoutAlign="start center">-->
          <svg-icon src="assets/icons/icon-check-ok.svg"></svg-icon>
          <h6 tabindex="-1" fxFlexOffset="7px">{{ 'card.SUBSCRIBED' | translate }}</h6>
        </div>

        <!-- Partecipazione effettuata -->
        <div
          *ngIf="hasLive() && !isCarouselOfPrems && !_isCompleted && _isEnrolled && hasSubscription() && !_eventEnded && !_isWebinarEnrolled && !_isEventEnrolled"
          [fxFlexOffset]="'8px'" [fxFlexOffset.xs]="'8px'" tabindex="-1" fxLayout="row" fxLayoutAlign="start center">
          <svg-icon src="assets/icons/icon-check-ok.svg"></svg-icon>
          <h6 tabindex="-1" fxFlexOffset="7px">{{ 'card.PARTICIPATED' | translate }}</h6>
        </div>

        <!-- Partecipazione effettuata -->
        <div
          *ngIf="hasLive() && !isCarouselOfPrems && !_isCompleted && (_isWebinarEnrolled || _isEventEnrolled) && hasSubscription()"
          [fxFlexOffset]="'8px'" [fxFlexOffset.xs]="'8px'" tabindex="-1" fxLayout="row" fxLayoutAlign="start center">
          <svg-icon src="assets/icons/icon-check-ok.svg"></svg-icon>
          <h6 *ngIf="_isWebinarEnrolled && !_isEventEnrolled" tabindex="-1" fxFlexOffset="7px">{{
            'card.SUBSCRIBED_ONLINE_LIVE' | translate }}</h6>
          <h6 *ngIf="_isEventEnrolled && !_isWebinarEnrolled" tabindex="-1" fxFlexOffset="7px">{{
            'card.SUBSCRIBED_ONLINE_PRESENCE' | translate }}</h6>
          <h6 fxShow fxHide.xs *ngIf="_isWebinarEnrolled && _isEventEnrolled" tabindex="-1" fxFlexOffset="7px">{{
            'card.SUBSCRIBED_ONLINE_LIVE_PRESENCE' | translate }}</h6>
          <h6 fxShow.xs fxHide *ngIf="_isWebinarEnrolled && _isEventEnrolled" tabindex="-1" fxFlexOffset="7px">{{
            'card.SUBSCRIBED_ONLINE_LIVE_PRESENCE_MOBILE' | translate }}</h6>
        </div>

        <!-- Non sei ancora iscritto -->
        <div
          *ngIf="!isCarouselOfPrems && !_isCompleted && !_isEnrolled && hasSubscription() && !_eventEnded && !_isWebinarEnrolled && !_isEventEnrolled"
          [fxFlexOffset]="(isTopLiveEvent() || (isGeneralEvent() && _location)) ? '8px' : '24px'"
          [fxFlexOffset.xs]="(isTopLiveEvent() || (isGeneralEvent() && _location)) ? '8px' : '22px'" tabindex="-1"
          fxLayout="row" fxLayoutAlign="start center">
          <svg-icon src="assets/icons/icon-minus-outline-new.svg" [ngClass]="{'icon-bold': isSmartEnergy()}">
          </svg-icon>

          <!-- non sei ancora iscritto -->
          <h6 *ngIf="(!isLiveEvent() || (_availableSeats > 0 && !_isEndEnrollDate)) && !hasLive()" fxFlexOffset="8px"
            tabindex="-1">
            {{ ('card.NOT_SUBSCRIBED' + (femaleUser ? '_F' : '')) | translate }}
          </h6>

          <!-- non hai ancora partecipato -->
          <h6 *ngIf="(!isLiveEvent() || (_availableSeats > 0 && !_isEndEnrollDate)) && hasLive()" fxFlexOffset="8px"
            tabindex="-1">
            {{ ('card.NOT_PARTICIPATED' + (femaleUser ? '_F' : '')) | translate }}
          </h6>

          <!-- Partecipazioni chiuse -->
          <h6 *ngIf="isLiveEvent() && (!_availableSeats || _availableSeats === 0 || _isEndEnrollDate)"
            fxFlexOffset="8px" tabindex="-1" translate="card.PARTICIPATIONS_CLOSED">
          </h6>

        </div>
        <!-- Posizione Job -->
        <div *ngIf="isJob()" tabindex="-1" fxFlexOffset="16px" fxLayoutGap="4px" fxLayout="row"
          fxLayoutAlign="start center">
          <svg-icon class="position" src="assets/icons/icon-map-pin.svg"></svg-icon>
          <h6 tabindex="-1">{{ _location }}</h6>
        </div>

        <!-- Posto (online/localita' fisica) del corso top ten -->
        <div *ngIf="isTopTenCourses()" tabindex="-1" fxLayout="row" fxLayoutAlign="start center">
          <svg-icon [applyCss]="true" src="assets/icons/icon-map-pin.svg"></svg-icon>
          <!-- non sei ancora iscritto -->
          <p class="top-ten-courses-where" fxFlexOffset="8px" tabindex="-1">
            {{ _where }}
          </p>
        </div>

        <!-- Check candidatura -->
        <div *ngIf="isJob() && _hasApplied" tabindex="-1" fxFlexOffset="16px" fxLayout="row"
          fxLayoutAlign="start center">
          <svg-icon class="position" src="assets/icons/icon-check-ok.svg"></svg-icon>
          <h6 tabindex="-1">{{ 'card.APPLICATION_SENT' | translate }}</h6>
        </div>

        <!-- STATO -->
        <div tabindex="-1" fxLayout="row" fxLayoutAlign="start center">
          <svg-icon id="status__icon" [src]="_iconSrc"></svg-icon>
          <p class="status" fxFlexOffset="8px" tabindex="-1">
            {{ _userStatus }}
          </p>
        </div>
      </div>

      <div *ngIf="isJob()" fxLayout="row nowrap" fxLayoutAlign="space-between center">
        <div *ngIf="_arguments && _arguments.length > 0" fxLayout="row wrap" fxLayoutAlign="start center"
          class="arguments-container">
          <generali-badge *ngFor="let argument of _arguments; let first = first; trackBy: trackByTagId"
            (click)="onTagClickEvent($event, argument)" style="margin-bottom: 8px; margin-right: 8px"
            [title]="argument.title" type="third-tag">
          </generali-badge>
        </div>

        <div fxLayout="row wrap" class="company-logo-container" fxLayoutAlign="start center">
          <!-- Logo alleanza -->
          <svg-icon *ngIf="_company == 'Alleanza Assicurazioni S.p.A.'" src="assets/img/logo-alleanza-cut.svg">
          </svg-icon>

          <!-- Logo generali -->
          <img *ngIf="_company != 'Alleanza Assicurazioni S.p.A.'" tabindex="-1" role="img" title="Logo generali"
            src="assets/img/logo-generali-cut.png">
        </div>
      </div>


      <!-- CARD CONTENT FOOTER: ARGUMENTS + PREFERITI -->
      <div fxLayoutAlign="space-between center" *ngIf="isTopTenCourses()">
        <div fxLayout="row">
          <ng-container *ngIf="_arguments && _arguments.length">
            <generali-badge *ngFor="let tag of _arguments; let first = first;" (click)="onTagClickEvent($event, tag)"
              [title]="tag.title" type="third-tag" [ngStyle]="{'badge-margin': isArticle()}">
            </generali-badge>
          </ng-container>
        </div>
        <svg-icon (click)="emitToggleBookmark($event)" [applyClass]="true" svgClass="userCourse__like"
          [ngClass]="{ 'is-switching-bookmark': _isSwitchingBookmark }"
          [src]="_isBookmarked ? 'assets/icons/icon-heart-fill.svg' : 'assets/icons/icon-heart.svg'"></svg-icon>
      </div>
      <div
        *ngIf="(isCourse() || isGeneralEvent() || _type == 'on-demand-event' || isArticle()) && _arguments && _arguments.length > 0"
        fxLayout="row wrap" fxLayoutAlign="start center" [ngClass]="isArticle() ? 'article-flex' : ''">
        <generali-badge *ngFor="let argument of _arguments; let first = first; trackBy: trackByTagId"
          (click)="onTagClickEvent($event, argument)" [title]="argument.title" type="third-tag">
        </generali-badge>
      </div>
    </div>
  </div>
</div>

<!-- Card dei corsi cm2 -->
<ng-container *ngIf="isCm2Course()">
  <div [className]="'card glp ' + (!isShowAll ?  'align-space-between' : 'align-center-center')"
    style="margin-bottom: 0.5em;" (click)="onCardClick.emit(_card)">

    <!-- show all -->
    <div *ngIf="isShowAll" fxLayout="row" fxLayoutAlign="center center" class="cursor-pointer view-all">
      <h6 class="cmCourse">
        {{ viewAllLabel || ('card.VIEW_ALL_GO' | translate: { value: viewAllNumber } | uppercase ) }}
      </h6>
      <svg-icon src="assets/icons/icon-view-all-go.svg">
      </svg-icon>
    </div>

    <!-- card image -->
    <div *ngIf="!isShowAll" class="card-image" [ngStyle]="{ 'background-image': 'url(' + _coverImage + ')' }">
    </div>

    <!-- Percentuale di avanzamento -->
    <div *ngIf="_percentage && _percentage > 0 && !isShowAll" class="course-percentage flex-hide-xs">
      <div class="percentage-bar" [ngStyle]="{'width': (_percentage + '%')}"></div>
    </div>

    <!-- content -->
    <div *ngIf="!isShowAll" class="card-content" fxLayout="column" fxLayoutAlign="space-between stretch">

      <!-- Titolo card -->
      <div fxLayout="column">
        <!--  -->
        <div [tooltip]="_title" [showTooltip]="showTooltip" class="userCourse" tabindex="-1" *ngIf="!isCarouselOfPrems">

          <p class="course-type">{{ _courseType }}</p>

          <!-- Durata x minuti / Data e ora -->
          <p class="when">{{ _when }}</p>

          <h2 class="title">{{ _title }}</h2>

          <!-- Posto (online/localita' fisica) -->
          <div tabindex="-1" fxLayout="row" fxLayoutAlign="start center">
            <svg-icon [applyCss]="true" src="assets/icons/icon-map-pin.svg"></svg-icon>
            <!-- non sei ancora iscritto -->
            <p class="where" fxFlexOffset="8px" tabindex="-1"> {{ _where }}</p>
          </div>

          <!-- STATO -->
          <div tabindex="-1" fxLayout="row" fxLayoutAlign="start center">
            <svg-icon id="status__icon" [src]="_iconSrc"></svg-icon>
            <p class="user-status" fxFlexOffset="8px" tabindex="-1">{{ _userStatus }}</p>
          </div>
        </div>
      </div>

      <!-- CARD CONTENT FOOTER: ARGUMENTS + PREFERITI -->
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="truncate-badge" fxLayout="row">
          <ng-container *ngIf="_arguments && _arguments.length">
            <generali-badge *ngFor="let tag of _arguments; let first = first;" (click)="onTagClickEvent($event, tag)"
              [title]="tag.title" class="glp-badge" type="glp-badge">
            </generali-badge>
          </ng-container>
        </div>
        <svg-icon (click)="emitToggleBookmark($event)" [applyClass]="true" svgClass="userCourse__like"
          [ngClass]="{ 'is-switching-bookmark': _isSwitchingBookmark }"
          [src]="_isBookmarked ? 'assets/icons/icon-heart-fill.svg' : 'assets/icons/icon-heart.svg'"></svg-icon>
      </div>
    </div>
    <!-- END content -->

  </div>
</ng-container>

<!-- Card degli oggetti library -->
<ng-container *ngIf="isLibraryItem()">
  <div [className]="'card glp ' + (!isShowAll ?  'align-space-between' : 'align-center-center')"
    style="margin-bottom: 0.5em;" (click)="onCardClick.emit(_card)">

    <!-- show all -->
    <div *ngIf="isShowAll" fxLayout="row" fxLayoutAlign="center center" class="cursor-pointer view-all">
      <h6 class="cmCourse">
        {{ viewAllLabel || ('card.VIEW_ALL_GO' | translate: { value: viewAllNumber } | uppercase ) }}
      </h6>
      <svg-icon src="assets/icons/icon-view-all-go.svg">
      </svg-icon>
    </div>

    <!-- Immagine della card -->
    <div *ngIf="!isShowAll" class="card-image" [ngStyle]="{ 'background-image': 'url(' + _coverImage + ')' }">
    </div>

    <!-- Percentuale di avanzamento -->
    <div *ngIf="_percentage && _percentage > 0 && !isShowAll" class="course-percentage flex-hide-xs">
      <div class="percentage-bar" [ngStyle]="{'width': (_percentage + '%')}"></div>
    </div>

    <div *ngIf="!isShowAll" class="card-content" fxLayout="column" fxLayoutAlign="space-between stretch">
      <div fxLayout="column">

        <!--  -->
        <div [tooltip]="_title" [showTooltip]="showTooltip" class="libraryItem" fxLayout="column" tabindex="-1"
          *ngIf="!isCarouselOfPrems">

          <!-- Tipologia -->
          <p class="course-type">{{ _courseType }}</p>

          <!-- Durata -->
          <p class="when">{{ _when || '' }}</p>

          <!-- Titolo -->
          <h2 class="title">{{ _title }}</h2>

          <!-- Luogo (sarà solo online, dato che è un corso library)-->
          <div tabindex="-1" fxLayout="row" fxLayoutAlign="start center">
            <svg-icon [applyCss]="true" src="assets/icons/icon-map-pin.svg"></svg-icon>
            <p class="where" fxFlexOffset="8px" tabindex="-1"> {{ _where }}</p>
          </div>

          <!-- Stato dell'utente (nel caso sia un oggetto atomico, ad es. un video o uno scorm) -->
          <div *ngIf="_card.isAtomicItem" tabindex="-1" fxLayout="row" fxLayoutAlign="start center">
            <svg-icon id="library-item-status-icon" [src]="_iconSrc"></svg-icon>
            <p class="user-status" fxFlexOffset="8px" tabindex="-1"> {{ _userStatus }}</p>
          </div>

          <!-- Numero di oggetti completati (nel caso sia un oggetto coontenitore, ad es. una playlist) -->
          <div *ngIf="_card.isContainerItem" tabindex="-1" fxLayout="row" fxLayoutAlign="start center">
            <p class="user-status" fxFlexOffset="8px" tabindex="-1"> {{ _card.completedItemsDesc }}</p>
          </div>
        </div>
      </div>

      <!-- CARD CONTENT FOOTER: ARGUMENTS + PREFERITI -->
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="truncate-badge" fxLayout="row">
          <ng-container *ngIf="_arguments && _arguments.length">
            <generali-badge *ngFor="let tag of _arguments; let first = first;" (click)="onTagClickEvent($event, tag)"
              [title]="tag.title" class="glp-badge" type="glp-badge">
            </generali-badge>
          </ng-container>
        </div>
        <svg-icon (click)="emitToggleBookmark($event)" [applyClass]="true" svgClass="libraryItem-bookmarks"
          [ngClass]="{ 'is-switching-bookmark': _isSwitchingBookmark }"
          [src]="_isBookmarked ? 'assets/icons/icon-heart-fill.svg' : 'assets/icons/icon-heart.svg'"></svg-icon>
      </div>
    </div>
    <!-- END content -->

  </div>
</ng-container>

<!-- Card delle collezioni dal web -->
<div class="isWebCollection" *ngIf="isWebCollection()">

  <div class="multicard-wrapper">
    <div class="line-1" [ngClass]="{'is-show-all': isShowAll}"></div>
    <div class="line-2" [ngClass]="{'is-show-all': isShowAll}"></div>
  </div>

  <div (click)="onCardClick.emit(_card)" [ngClass]="{'pointer-none': isVetrin}" [className]="'card web-collection glp ' +
(!isShowAll ?  'align-space-between' : 'align-center-center')">
    <div *ngIf="isShowAll" fxLayout="row" fxLayoutAlign="center center" class="cursor-pointer view-all">
      <h6 class="webCollection">{{ viewAllLabel || ('card.VIEW_ALL_GO' | translate: { value: viewAllNumber } |
        uppercase
        ) }}</h6>
      <svg-icon src="assets/icons/icon-view-all-go.svg">
      </svg-icon>
    </div>

    <div *ngIf="!isShowAll" class="card-image isCollectionCard"
      [ngStyle]="{ 'background-image': 'url(' + _coverImage + ')' }">
    </div>

    <!-- Intestazione delle card delle collezioni -->
    <ng-container *ngIf="_card">

      <svg class="card-collection-top" width="378" height="66" viewBox="0 0 378 66" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M-3.09061e-05 8H378C378 8 -3.09061e-05 123.789 -3.09061e-05 27.7975C-0.000236026 5.00453 -3.09061e-05 8 -3.09061e-05 8Z"
          fill="#47D496" fill-opacity="0.9" />
        <path d="M3.39147e-05 0H378L378 8H-3.09061e-05L3.39147e-05 0Z" fill="#47D496" fill-opacity="0.9" />
      </svg>

      <span class="collection-label">{{ _card.collectionLabel }}</span>

      <svg class="card-collection-bottom" width="378" height="59" viewBox="0 0 378 59" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M378 58H-9.15527e-05C-9.15527e-05 58 378 -57.7891 378 38.2025C378 60.9955 378 58 378 58Z"
          fill="#47D496" fill-opacity="0.9" />
      </svg>

      <span class="collection-content-label">{{ _card.contentLabel }}</span>
    </ng-container>

    <generali-badge [isVetrin]="isVetrin" class="card-badge" *ngIf="_showBadge && !isShowAll" [title]="_badgeTitle"
      [type]="_badgeType">
    </generali-badge>

    <!-- Percentuale di avanzamento -->
    <div *ngIf="_percentage && _percentage > 0 && !isShowAll" class="percentage flex-hide-xs">
      <div class="percentage-bar" [ngStyle]="{'width': (_percentage + '%')}"></div>
    </div>

    <div *ngIf="!isShowAll" class="card-content" fxLayout="column" fxLayoutAlign="space-between stretch">
      <!--  -->
      <div [tooltip]="_title" fxLayout="column">
        <!-- Titolo -->
        <p class="only-2-rows title" *ngIf="_title" tabindex="-1" [ngClass]="{'bold': isSmartEnergy()}">
          {{ _title }}</p>

        <!-- Stato -->
        <div *ngIf="_userStatus" tabindex="-1" fxLayout="row" fxLayoutAlign="start center">
          <svg-icon id="status__icon" [src]="_iconSrc"></svg-icon>
          <h6 fxFlexOffset="8px" tabindex="-1">
            {{ _userStatus }}
          </h6>
        </div>
      </div>

      <!-- Categorie
      <div class="categories" fxLayout="row wrap" fxLayoutAlign="start center" fxFlexOffset="28px"
        fxFlexOffset.xs="22px">
        <!-- Desktop
        <ng-container *ngIf="_arguments && _arguments.length">
          <generali-badge [ngClass]="{'opacity0-5': isCarouselOfPrems}" [isVetrin]="isVetrin" class="flex-hide-xs"
            *ngFor="let argument of _arguments; let first = first; trackBy: trackByTagId"
            style="margin-bottom: 8px; margin-right: 8px" (click)="onTagClickEvent($event, argument, _card)"
            [title]="argument.title" type="third-tag" [bgTransparent]="isPastEvent() && distinctForPastEvents">
          </generali-badge>
          <!-- Mobile
          <generali-badge [ngClass]="{'opacity0-5': isCarouselOfPrems}" [isVetrin]="isVetrin" class="flex-show-xs"
            *ngFor="let argument of _argumentsMobile; let first = first; trackBy: trackByTagId"
            style="margin-bottom: 8px; margin-right: 8px" (click)="onTagClickEvent($event, argument, _card)"
            [title]="argument.title" type="third-tag" [bgTransparent]="isPastEvent() && distinctForPastEvents">
          </generali-badge>
        </ng-container>

      </div> -->

      <!-- Tag delle collezioni -->
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="truncate-badge" fxLayout="row">
          <ng-container *ngIf="_arguments && _arguments.length">
            <generali-badge *ngFor="let tag of _arguments; let first = first;" (click)="onTagClickEvent($event, tag)"
              [title]="tag.title" class="glp-badge" type="glp-badge">
            </generali-badge>
          </ng-container>
        </div>
        <svg-icon (click)="emitToggleBookmark($event)" [applyClass]="true" svgClass="likeCollection"
          [ngClass]="{ 'is-switching-bookmark': _isSwitchingBookmark }"
          [src]="_isBookmarked ? 'assets/icons/icon-heart-fill.svg' : 'assets/icons/icon-heart.svg'">
        </svg-icon>
      </div>

    </div>

  </div>
</div>

<!-- Card degli oggetti web (che fanno parte di una collezione dal web)-->
<div *ngIf="isItemOfWebCollection()">
  <div (click)="onCardClick.emit(_card)" [ngClass]="{'pointer-none': isVetrin}" [className]="'card playlist-item glp ' +
(!isShowAll ?  'align-space-between' : 'align-center-center')">

    <div *ngIf="isShowAll" fxLayout="row" fxLayoutAlign="center center" class="cursor-pointer view-all">
      <h6 class="cmCourse">{{ viewAllLabel || ('card.VIEW_ALL_GO' | translate: { value: viewAllNumber } |
        uppercase
        ) }}</h6>
      <svg-icon [src]="isSmartEnergy() ? 'assets/icons/icon-view-all-smart.svg' : 'assets/icons/icon-view-all-go.svg'">
      </svg-icon>
    </div>

    <div *ngIf="!isShowAll" class="card-image" [ngStyle]="{ 'background-image': 'url(' + _coverImage + ')' }">
    </div>

    <!-- Percentuale di avanzamento -->
    <div *ngIf="_percentage && _percentage > 0 && !isShowAll" class="percentage flex-hide-xs">
      <div class="percentage-bar" [ngStyle]="{'width': (_percentage + '%')}"></div>
    </div>

    <div *ngIf="!isShowAll" class="card-content" fxLayout="column" fxLayoutAlign="space-between stretch">
      <!-- Titolo card -->
      <!--  -->
      <div [tooltip]="_title" fxLayout="column" class="libraryItem">

        <!-- Tipologia -->
        <p *ngIf="_translatedCourseType" class="item-of-web-type">{{ _translatedCourseType }}
        </p>

        <p *ngIf="_card.courseDuration" class="when">
          <!-- Durata x minuti / Data e ora -->
          {{ _card.courseDuration }}
        </p>

        <!-- Titolo -->
        <p class="title" [ngClass]="titleOnlyOneRow? 'only-1-row' : 'only-2-rows'" *ngIf="_title" tabindex="-1">
          {{ _title }}</p>

        <!-- Luogo (sarà solo online, dato che è un corso web)-->
        <div tabindex="-1" fxLayout="row" fxLayoutAlign="start center">
          <svg-icon [applyCss]="true" src="assets/icons/icon-map-pin.svg"></svg-icon>
          <p class="where" fxFlexOffset="8px" tabindex="-1"> Online</p>
        </div>

        <!-- Stato -->
        <div tabindex="-1" fxLayout="row" fxLayoutAlign="start center">
          <svg-icon id="status__icon"
            [src]="_card.isCompleted ? 'assets/icons/green-check.svg' : 'assets/icons/orange-check.svg'"></svg-icon>
          <h6 fxFlexOffset="8px" tabindex="-1">
            {{ _card.isCompleted ? 'Completato' : 'Da completare' }}
          </h6>
        </div>
      </div>

      <!-- Tag -->
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="truncate-badge" fxLayout="row">
          <ng-container *ngIf="_card.courseCategories && _card.courseCategories.length">
            <generali-badge [isVetrin]="isVetrin"
              *ngFor="let argument of _card.courseCategories; let first = first; trackBy: trackByCourseCategoryId"
              (click)="onTagClickEvent($event, argument, _card)" [title]="argument.courseCategoryName" class="glp-badge"
              type="glp-badge">
            </generali-badge>
          </ng-container>
        </div>
        <svg-icon (click)="emitToggleBookmark($event)" [applyClass]="true" svgClass="likeCollection"
          [ngClass]="{ 'is-switching-bookmark': _isSwitchingBookmark }"
          [src]="_isBookmarked ? 'assets/icons/icon-heart-fill.svg' : 'assets/icons/icon-heart.svg'">
        </svg-icon>
      </div>
    </div>
  </div>
</div>

<!-- Card delle Playlist della Library -->
<div class="isPlaylistItem" *ngIf="isPlaylistItem()">
  <div class="multicard-wrapper">
    <div class="line-1" [ngClass]="{'is-show-all': isShowAll}"></div>
    <div class="line-2" [ngClass]="{'is-show-all': isShowAll}"></div>
  </div>

  <div (click)="onCardClick.emit(_card)" [ngClass]="{'pointer-none': isVetrin}" [className]="'card playlist-item glp ' +
(!isShowAll ?  'align-space-between' : 'align-center-center')">

    <div *ngIf="isShowAll" fxLayout="row" fxLayoutAlign="center center" class="cursor-pointer view-all">
      <h6 class="cmCourse">{{ viewAllLabel || ('card.VIEW_ALL_GO' | translate: { value: viewAllNumber } |
        uppercase
        ) }}</h6>
      <svg-icon src="assets/icons/icon-view-all-go.svg">
      </svg-icon>
    </div>

    <div *ngIf="!isShowAll" class="card-image isCollectionCard"
      [ngStyle]="{ 'background-image': 'url(' + _coverImage + ')' }">
    </div>

    <!-- Intestazione delle card delle collezioni -->
    <ng-container *ngIf="_card && !_preventCollectionForYouWaves">
      <svg class="card-collection-top" width="378" height="66" viewBox="0 0 378 66" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M-3.09061e-05 8H378C378 8 -3.09061e-05 123.789 -3.09061e-05 27.7975C-0.000236026 5.00453 -3.09061e-05 8 -3.09061e-05 8Z"
          fill="#DC4F3E" fill-opacity="0.9" />
        <path d="M3.39147e-05 0H378L378 8H-3.09061e-05L3.39147e-05 0Z" fill="#DC4F3E" fill-opacity="0.9" />
      </svg>

      <span class="collection-label">{{ _card.collectionLabel }}</span>

      <svg class="card-collection-bottom" width="378" height="59" viewBox="0 0 378 59" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M378 58H-9.15527e-05C-9.15527e-05 58 378 -57.7891 378 38.2025C378 60.9955 378 58 378 58Z"
          fill="#DC4F3E" fill-opacity="0.9" />
      </svg>

      <span class="collection-content-label">{{ _card.contentLabel }}</span>
    </ng-container>

    <generali-badge [isVetrin]="isVetrin" class="card-badge" *ngIf="_showBadge && !isShowAll" [title]="_badgeTitle"
      [type]="_badgeType">
    </generali-badge>

    <!-- Percentuale di avanzamento -->
    <div *ngIf="_percentage && _percentage > 0 && !isShowAll" class="percentage flex-hide-xs">
      <div class="percentage-bar" [ngStyle]="{'width': (_percentage + '%')}"></div>
    </div>

    <div *ngIf="!isShowAll" class="card-content" fxLayout="column" fxLayoutAlign="space-between stretch">
      <!--   -->
      <div [tooltip]="_title" fxLayout="column">
        <!-- Titolo card -->
        <p class="only-2-rows title" *ngIf="_title" tabindex="-1">
          {{ _title }}</p>

        <!-- Stato -->
        <div *ngIf="_userStatus" tabindex="-1" fxLayout="row" fxLayoutAlign="start center">
          <svg-icon id="status__icon" [src]="_iconSrc"></svg-icon>
          <h6 fxFlexOffset="8px" tabindex="-1">
            {{ _userStatus }}
          </h6>
        </div>
      </div>

      <!-- Categorie
      <div class="categories" fxLayout="row wrap" fxLayoutAlign="start center" fxFlexOffset="28px"
        fxFlexOffset.xs="22px">
        <!-- Desktop
        <ng-container *ngIf="_arguments && _arguments.length">
          <generali-badge [ngClass]="{'opacity0-5': isCarouselOfPrems}" [isVetrin]="isVetrin" class="flex-hide-xs"
            *ngFor="let argument of _arguments; let first = first; trackBy: trackByTagId"
            style="margin-bottom: 8px; margin-right: 8px" (click)="onTagClickEvent($event, argument, _card)"
            [title]="argument.title" type="third-tag" [bgTransparent]="isPastEvent() && distinctForPastEvents">
          </generali-badge>
          <!-- Mobile
          <generali-badge [ngClass]="{'opacity0-5': isCarouselOfPrems}" [isVetrin]="isVetrin" class="flex-show-xs"
            *ngFor="let argument of _argumentsMobile; let first = first; trackBy: trackByTagId"
            style="margin-bottom: 8px; margin-right: 8px" (click)="onTagClickEvent($event, argument, _card)"
            [title]="argument.title" type="third-tag" [bgTransparent]="isPastEvent() && distinctForPastEvents">
          </generali-badge>
        </ng-container>
      </div> -->

      <!-- Tag -->
      <div fxLayout="row" fxLayoutAlign="space-between center">
        <div class="truncate-badge" fxLayout="row">
          <ng-container *ngIf="_arguments && _arguments.length">
            <generali-badge *ngFor="let tag of _arguments; let first = first;" (click)="onTagClickEvent($event, tag)"
              [title]="tag.title" class="glp-badge" type="glp-badge">
            </generali-badge>
          </ng-container>
        </div>
        <!-- Aggiungi ai preferiti -->
        <svg-icon (click)="emitToggleBookmark($event)" [applyClass]="true" svgClass="likeCollection"
          [ngClass]="{ 'is-switching-bookmark': _isSwitchingBookmark }"
          [src]="_isBookmarked ? 'assets/icons/icon-heart-fill.svg' : 'assets/icons/icon-heart.svg'">
        </svg-icon>
      </div>
    </div>
  </div>
</div>