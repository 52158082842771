<div *ngIf="isLoadingItems || (_cardList && _cardList.length)" class="card-carousel-container" fxLayout="row"
  [ngClass]="_libraryStyle">

  <div class="side-space-pannel" fxLayout="column">

    <div fxFlex class="flex-hide-xs" fxLayout="row" fxLayoutAlign="end center" fxFlexOffset="80px">
      <!-- Scroll a sinistra -->
      <div *ngIf="_showLeftArrow" class="carousel-arrow" (click)="scrollLeft()" fxLayout="column"
        fxLayoutAlign="center center">
        <svg-icon src="assets/icons/icon-arrow-left-{{isSmartEnergy() ? 'smart' : 'go'}}.svg">
        </svg-icon>
      </div>
      <!-- Margine di 20px fisso -->
      <div fxFlexOffset="20px"></div>
    </div>

  </div>

  <div fxFlex fxLayout="column" class="card-carousel-content">

    <div fxLayout="row" fxLayoutAlign="space-between center" ngClass.xs="side-space-pannel-xs">
      <h4 tabindex="-1">{{ title }}</h4>

      <h6 *ngIf="!isLoadingItems && !_hideAllButton" class="flex-hide-xs" (click)="viewAllTap.emit()">
        <ng-container *ngIf="isSmartEnergy() && type != 'live-event'">
          {{ 'card.VIEW_ALL' | translate: { value: itemCount || _cardLength } }}
        </ng-container>
        <ng-container *ngIf="!isSmartEnergy() && type != 'job'">
          {{ 'card.VIEW_ALL_GO' | translate: { value: itemCount || _cardLength } | uppercase }}
        </ng-container>
        <ng-container *ngIf="!isSmartEnergy() && type == 'job'">
          {{ 'card.VIEW_ALL_GO_JOB' | translate: { value: itemCount || _cardLength } | uppercase }}
        </ng-container>
        <ng-container *ngIf="isSmartEnergy() && type == 'live-event'">
          {{ 'card.VIEW_ALL_GO_LIVE_EVENT' | translate: { value: itemCount || _cardLength } }}
        </ng-container>
      </h6>

      <svg-icon *ngIf="!isLoadingItems && !_hideAllButton" class="flex-show-xs" (click)="viewAllTap.emit()"
        src="assets/icons/icon-view-all-{{isSmartEnergy() ? 'smart' : 'go'}}.svg"></svg-icon>

    </div>

    <!-- Considerati i 15px di ombreggiatura (e margine delle cards) di smart energy -->
    <div [fxFlexOffset]="isSmartEnergy() ? '29px' : '33px'" [fxFlexOffset.xs]="isSmartEnergy() ? '24px' : '27px'"
      fxLayout="row" [fxLayoutAlign]="isLoadingItems ? 'center' : 'start center'">
      <!-- Loader -->
      <generali-loader [hidden]="!isLoadingItems"></generali-loader>

      <!-- Carosello -->
      <generali-carousel [fromHero]="scrollWithoutScrollbar"
        [preventOverflowMarginOnRight]="preventOverflowMarginOnRight" [hidden]="isLoadingItems" #carousel hidePoints
        fxFlex [contentLength]="_cardList?.length" [scrollToLeft]="_scrollToLeft.asObservable()"
        [scrollToRight]="_scrollToRight.asObservable()" [sectionId]="sectionId" (onScrollEvent)="onScrollEvent($event)"
        [isLoading]="isLoadingItems">
        <!-- Considerati i 15px di ombreggiatura (e margine delle cards) di smart energy -->
        <ng-container *ngIf="_cardList && _cardList.length">

          <generali-card [showTooltip]="showTooltip" [isVetrin]="isVetrin" [isCarouselOfPrems]="isCarouselOfPrems"
            *ngFor="let card of _cardList; let index = index; let last = last; let first = first; trackBy: trackBy"
            [fxFlexOffset]="first ? '0px' : (isSmartEnergy() ? '22px' : '20px')"
            [fxFlexOffset.xs]="first ? '15px' : (isSmartEnergy() ? '22px' : '15px')"
            [ngClass]="{'top-live-event': isTopLiveEvent || isTopTenCourses }" [cardNumber]="(index + 1)"
            [type]="(differentTypesForCard && card.cardType) ? card.cardType : type" [card]="card"
            (onCardClick)="onCardClick.emit(card)" (onTagClick)="onTagClick.emit($event)"
            [isSwitchingBookmark]="card.isSwitchingBookmark" [isBookmarked]="card.isBookmarked"
            (onToggleBookmark)="emitToggleBookmark($event)" [distinctForPastEvents]="distinctForPastEvents"
            [translatedCourseType]="_translatedCourseType"
            [titleOnlyOneRow]="titleOnlyOneRow">
          </generali-card>
        </ng-container>

        <!-- Carta Mostra tutti -->

        <generali-card [showTooltip]="showTooltip"
          *ngIf="!withTotalItemsMoreOne && !isVetrin && !isTopTenCourses && _cardLength && _cardLength > 5"
          [ngClass]="{'top-live-event': isTopLiveEvent}" [fxFlexOffset]="isSmartEnergy() ? '11px' : '20px'"
          [type]="type" [isShowAll]="true" (onCardClick)="viewAllTap.emit()" [viewAllNumber]="itemCount || _cardLength"
          (onToggleBookmark)="emitToggleBookmark($event)"
          [titleOnlyOneRow]="titleOnlyOneRow">
        </generali-card>


        <generali-card [showTooltip]="showTooltip"
          *ngIf="withTotalItemsMoreOne && !isVetrin && !isTopTenCourses && _cardLength && _cardLength > 5"
          [ngClass]="{'top-live-event': isTopLiveEvent}" [fxFlexOffset]="isSmartEnergy() ? '11px' : '20px'"
          [type]="type" [isShowAll]="true" (onCardClick)="viewAllTap.emit()"
          [viewAllNumber]="(itemCount + 1) || (_cardLength + 1)" (onToggleBookmark)="emitToggleBookmark($event)"
          [titleOnlyOneRow]="titleOnlyOneRow">
        </generali-card>
        <!-- Fine carta Mostra tutti -->
      </generali-carousel>
    </div>

  </div>

  <div class="side-space-pannel" fxLayout="column">
    <div fxFlex class="flex-hide-xs" fxLayout="row" fxLayoutAlign="start center" fxFlexOffset="80px">
      <!-- Margine di 20px fisso -->
      <div fxFlexOffset="20px"></div>
      <!-- Scroll a destra -->
      <div *ngIf="_showRightArrow" class="carousel-arrow" (click)="scrollRight()" fxLayout="column"
        fxLayoutAlign="center center">
        <svg-icon src="assets/icons/icon-arrow-right-{{isSmartEnergy() ? 'smart' : 'go'}}.svg"></svg-icon>
      </div>
    </div>

  </div>

</div>